import { get, set } from 'lodash-es'
import {
  EFlowParseJson,
  EFlowShowOn,
  EFlowElement,
  EJSONProps,
  EFlowRepeater,
  ETalentPanel
} from '@twago/flows'

export const state = () => ({
  useProjectData: null,
  structure: [],
  categoriesList: [],
  orphanPoolsList: [],
  categorizedPoolsList: [],
  languagesList: [],
  onboardingType: EFlowShowOn.LONG_ONBOARDING,
  parsedDataUsed: false
})

export const actions = {
  createStructure({ commit }, payload) {
    commit('STRUCTURE', payload)
  },

  updateData({ state, commit, rootState }, payload) {
    const defineObj = state.structure.find(
      (el) => el.name === payload.name || el.type === payload.name
    )
    if (
      payload.name === EFlowParseJson.FIELD_PHONE ||
      payload.name === EFlowParseJson.FIELD_HOME_LINE1
    ) {
      console.log(
        `[TWAGOS-13865] - user: ${rootState.auth.user.id} - onboarding/updateData ${payload.name} payload: `,
        { ...payload }
      )
    }

    if (Object.hasOwnProperty.call(payload, 'value')) {
      commit('UPDATE_STRUCTURE', {
        defineObj,
        prop: 'value',
        value: payload.value
      })
    }

    if (Object.hasOwnProperty.call(payload, 'canParse')) {
      commit('UPDATE_STRUCTURE', {
        defineObj,
        prop: 'canParse',
        value: payload.canParse
      })
    }

    if (Object.hasOwnProperty.call(payload, 'disabled')) {
      commit('UPDATE_STRUCTURE', {
        defineObj,
        prop: 'disabled',
        value: payload.disabled
      })
    }
  },

  applyParsing({ state, dispatch, rootState }, payload) {
    const skills = get(payload, 'skills')
    const toolsAndTechnologies = get(payload, 'toolsAndTechnologies')
    const languages = get(payload, 'masteredLanguages')
    const jh = get(payload, 'employmentHistory')
    const eh = get(payload, 'educationHistory')
    const ch = get(payload, 'certificateHistory')
    const linkedinHandle = get(payload, 'linkedIn')

    const coreData = [
      [ETalentPanel.SUMMARY, get(payload, EFlowParseJson.FIELD_DESCRIPTION)],
      [ETalentPanel.JOB_TITLE, get(payload, EFlowParseJson.FIELD_PROFESSIONAL_TITLE)]
    ]

    const addressData = [
      [EFlowParseJson.FIELD_HOME_LINE1, get(payload, 'addressLine1')],
      [EFlowParseJson.FIELD_HOME_LINE2, get(payload, 'addressLine2')],
      [EFlowParseJson.FIELD_HOME_STREET, get(payload, 'addressStreet')],
      [EFlowParseJson.FIELD_HOME_ZIP, get(payload, 'addressZip')],
      [EFlowParseJson.FIELD_HOME_CITY, get(payload, 'addressCity')],
      [EFlowParseJson.FIELD_HOME_STATE, get(payload, 'addressState')],
      [
        EFlowParseJson.FIELD_HOME_COUNTRY,
        get(payload, 'addressCountry') || this.$auth.$state.user.residenceCountry
      ]
    ]

    for (const el of coreData) {
      dispatch('updateData', {
        name: el[0],
        value: el[1] || null
      })
    }

    if (state.structure.some((e) => e.type === EFlowElement.PROFILE_ADDRESS)) {
      for (const el of addressData) {
        const addressBlock = state.structure.find((e) => e.type === EFlowElement.PROFILE_ADDRESS)

        console.log(
          `[TWAGOS-13865] - user: ${rootState.auth.user.id} - onboarding/applyParsing dispatch updateData: ${el[0]} value: ${el[1]}`
        )

        dispatch('updateData', {
          name: addressBlock.name,
          value: { ...addressBlock.value, [el[0]]: el[1] }
        })
      }
    } else {
      for (const el of addressData) {
        dispatch('updateData', {
          name: el[0],
          value: el[1] || null
        })
      }
    }

    dispatch('updateData', {
      name: EFlowParseJson.FIELD_PHONE,
      value: {
        code: get(payload, 'mobilePhonePrefix') || '',
        number: get(payload, 'mobilePhoneLocal') || ''
      }
    })

    console.log(
      `[TWAGOS-13865] - user: ${
        rootState.auth.user.id
      } - onboarding/applyParsing dispatch updateData, ${EFlowParseJson.FIELD_PHONE} - code: ${
        get(payload, 'mobilePhonePrefix') || ''
      }, number: ${get(payload, 'mobilePhoneLocal') || ''}`
    )

    if (skills) {
      dispatch('updateData', {
        name: EFlowParseJson.FIELD_SKILLS,
        value: skills.map((e) => {
          return e?.text
        })
      })
    }

    if (toolsAndTechnologies) {
      dispatch('updateData', {
        name: EFlowParseJson.FIELD_TOOLS_TECH,
        value: toolsAndTechnologies.map((e) => {
          return e?.text
        })
      })
    }

    if (languages) {
      const defineValue = languages.map((el) => ({
        language: el.code ? el.code.toLowerCase() : '',
        proficiency: Number(el.proficiency) ?? 3
      }))

      dispatch('updateData', {
        name: EFlowParseJson.FIELD_LANG,
        value: defineValue
      })
    }

    if (jh) {
      const defineValue = jh.map((el) => ({
        title: get(el, 'title'),
        organization: get(el, 'organization'),
        description: el.description.split(' * ').join('\n* '),
        startDate: this.$checkPresent(get(el, 'start')),
        endDate: this.$checkPresent(get(el, 'end')),
        ongoing: get(el, 'ongoing')
      }))

      dispatch('updateData', {
        name: EFlowParseJson.JOB_HISTORY,
        value: defineValue
      })
    }

    if (eh) {
      const defineValue = eh.map((el) => ({
        title: get(el, 'title'),
        description: get(el, 'description'),
        startDate: this.$checkPresent(get(el, 'start')),
        endDate: this.$checkPresent(get(el, 'end')),
        ongoing: get(el, 'ongoing')
      }))

      dispatch('updateData', {
        name: EFlowParseJson.EDU_HISTORY,
        value: defineValue
      })
    }

    if (ch) {
      const defineValue = ch.map((el) => ({
        title: get(el, 'title'),
        description: get(el, 'description'),
        startDate: this.$checkPresent(get(el, 'start'))
      }))

      dispatch('updateData', {
        name: EFlowParseJson.CERT_HISTORY,
        value: defineValue
      })
    }

    if (linkedinHandle) {
      dispatch('updateData', {
        name: EFlowParseJson.FIELD_TEXT_SOCIAL,
        value: linkedinHandle
      })
    }
  },

  cleanParsedData({ state, commit }) {
    const parsedFields = [...Object.values(EFlowParseJson)]

    state.structure.forEach((el) => {
      if (!parsedFields.includes(el.name)) return
      switch (true) {
        case Array.isArray(el.value):
          commit('CLEAN_PARSED_DATA', { el, prop: 'value', value: [] })
          break
        case el.name === EFlowParseJson.FIELD_PHONE:
          commit('CLEAN_PARSED_DATA', { el, prop: 'value', value: { code: '', number: '' } })
          break
        case typeof el.value === 'object' && !Array.isArray(el.value) && el.value:
          commit('CLEAN_PARSED_DATA', { el, prop: 'value', value: {} })
          break
        case typeof el.value === 'string':
          commit('CLEAN_PARSED_DATA', { el, prop: 'value', value: '' })
          break
        default:
          commit('CLEAN_PARSED_DATA', { el, prop: 'value', value: null })
      }
    })
  }
}

export const mutations = {
  STRUCTURE: (state, payload) => (state.structure = payload),
  SET_PARSED_DATA_USED: (state, payload) => (state.parsedDataUsed = payload),

  UPDATE_STRUCTURE: (state, { defineObj, prop, value }) => {
    set(defineObj, prop, value)

    const useItems = [
      EFlowRepeater.JOB_HISTORY,
      EFlowRepeater.EDU_HISTORY,
      EFlowRepeater.CERT_HISTORY
    ]

    if (useItems.includes(get(defineObj, 'name'))) {
      const itemIdx = state.structure.findIndex((e) => e.name === defineObj.name)
      state.structure[itemIdx].value.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    }

    // FIXME: remove upon release
    // console.log(`🆘 ~ UPDATE OBJECT ===>`, JSON.stringify(defineObj, null, 3))
  },

  UPDATE_INNER_DATA: (state, payload) => {
    const defineObj = state.structure.find((el) => el.name === payload.parentName)

    for (const item of defineObj.form) {
      if (item.name === payload.name) {
        set(item, 'value', payload.value)
      }

      if (Object.hasOwnProperty.call(payload, 'target') && item.name === payload.target) {
        set(item, 'value', payload.value ? null : '')
        set(item, 'disabled', payload.value)
      }
    }
  },

  UPDATE_INNER_FORM: (state, { name, entries }) => {
    const structure = [...state.structure]
    const entriesArr = Object.entries(entries)
    const useObj = structure.find((el) => el.name === name)

    if (entriesArr.length) {
      for (const item of entriesArr) {
        const useItemIdx = useObj.form.findIndex((el) => el?.name === item[0])
        const useItemObj = useObj.form.find((el) => el?.name === item[0])

        if (useItemObj) {
          const defineObj = set(useItemObj, 'value', item[1])

          if (Object.hasOwnProperty.call(useItemObj, 'target')) {
            const updateState = useObj.form.find((el) => el.name === useItemObj.target)
            set(updateState, 'disabled', useItemObj.value)
          }

          useObj.form.splice(useItemIdx, 1, defineObj)
        }
      }
    } else {
      useObj.form.forEach((el) => {
        if (el.name === EJSONProps.CURRENT) {
          set(el, 'value', false)
        } else if (Object.hasOwnProperty.call(el, 'value')) {
          set(el, 'value', '')
        }

        if (Object.hasOwnProperty.call(el, 'disabled')) {
          set(el, 'disabled', false)
        }
      })
    }
  },

  CLEAN_PARSED_DATA: (state, { el, prop, value }) => set(el, prop, value),
  CATEGORIES_LIST: (state, payload) => (state.categoriesList = payload),
  ORPHAN_POOLS_LIST: (state, payload) => (state.orphanPoolsList = payload),
  CATEGORIZED_POOLS_LIST: (state, payload) => (state.categorizedPoolsList = payload),
  LANGUAGES_LIST: (state, payload) => (state.languagesList = payload),
  USE_PROJECT_DATA: (state, payload) => (state.useProjectData = payload),
  SET_ONBOARDING_TYPE: (state, payload) => (state.onboardingType = payload)
}

export const getters = {
  structure: (state) => state.structure,
  categoriesList: (state) => state.categoriesList,
  orphanPoolsList: (state) => state.orphanPoolsList,
  categorizedPoolsList: (state) => state.categorizedPoolsList,
  languagesList: (state) => state.languagesList,
  useProjectData: (state) => state.useProjectData,
  onboardingType: (state) => state.onboardingType
}
