import humanizeDuration from 'humanize-duration'
import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import { defineShortLocale, currencySign } from '@twago/configs'
import { get } from 'lodash-es'
import { clientTheme } from '@/config/theme'
import { EDateType, EJobApplicationStatus, EClientConfig } from '@/helpers/enums'
import { ELeConfig, mapClientConfig, mapLeConfig } from '@/config/config'

Vue.use(vueDebounce, {
  listenTo: ['input', 'change', 'keyup'],
  defaultTime: '200ms'
})

export default ({ $config, app, store, $vuetify }, inject) => {
  const applyClientTheme = clientTheme(store.state)

  $vuetify.theme.themes.light = {
    ...$vuetify.theme.defaults.light,
    ...applyClientTheme.light
  }

  inject('EClientConfig', EClientConfig)
  inject('useConfig', (key) => mapClientConfig(store.state.clientConfig, key))

  inject('ELeConfig', ELeConfig)
  inject('useLeConfig', (legalEntityName, key) =>
    mapLeConfig(store.state.leConfigs.get(legalEntityName), key)
  )

  inject('ampersandCorrection', (value) => {
    if (value) {
      return value.replaceAll('&amp;', '&')
    }
  })

  inject('defineLocale', (code) => {
    const useShort = defineShortLocale(code)
    const useShortCode = ['ua-UA'].includes(code) ? 'en' : useShort
    return useShortCode
  })

  inject('yearsList', () => {
    const currentYear = new Date().getFullYear()
    const min = currentYear - 90
    const max = currentYear + 10
    const years = []

    for (let i = currentYear; i <= max; i++) {
      years.unshift(i)
    }

    for (let i = currentYear; i >= min; i--) {
      years.push(i)
    }

    return years
  })

  inject('displayTime', (value, format = 'll') => {
    const currentlocale = app.$defineLocale(app.$i18n.locale)
    return app.$moment(value).locale(currentlocale).format(format)
  })

  // future proven approach after moment is dropped, please start using it now:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
  inject('displayDate', ({ value, format, options }) => {
    const currentlocale = app.$defineLocale(app.$i18n.locale)
    let defineFormat = {}
    let formattedDate = null
    const isString = typeof value === 'string'

    if (value) {
      switch (format) {
        case EDateType.SHORT:
          defineFormat = {
            year: 'numeric',
            month: 'short'
          }
          if (isString) {
            const [year, month] = value.split('-').map(Number)
            formattedDate = new Date(year, month - 1)
          }
          break
        case EDateType.LONG:
          defineFormat = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }
          if (isString) {
            const [year, month, day] = value.split('-').map(Number)
            formattedDate = new Date(year, month - 1, day)
          }
          break
        case EDateType.YEAR:
          defineFormat = {
            year: 'numeric'
          }
          if (isString) {
            const [year] = value.split('-').map(Number)
            formattedDate = new Date(year, 0, 1)
          }
          break
        case EDateType.LONG_WITH_TIME:
          defineFormat = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          }
          if (typeof value === 'string') {
            const [datePart, timePart] = value.split('T')
            const [year, month, day] = datePart.split('-').map(Number)
            const [hour, minute] = (timePart || '00:00').split(':').map(Number) // Default time to 00:00 if not provided
            formattedDate = new Date(year, (month || 1) - 1, day || 1, hour || 0, minute || 0)
          }
          break
        default:
          defineFormat = {
            ...options
          }
          break
      }

      return new Intl.DateTimeFormat(currentlocale, defineFormat).format(
        formattedDate || new Date(value)
      )
    }
  })

  inject('humanizedDate', (start, end = new Date()) => {
    const starting = app.$moment(start).utc()
    const ending = app.$moment(end).utc()
    const getMonths = ending.diff(starting, 'month')
    const humanizing = ending.diff(starting)
    let humanized = ''

    const localizedHumanization = () => ({
      en: {
        y: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.y.many')
            : app.$i18n.t('talent.humanized.date.y'),
        mo: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.mo.many')
            : app.$i18n.t('talent.humanized.date.mo'),
        w: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.w.many')
            : app.$i18n.t('talent.humanized.date.w'),
        d: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.d.many')
            : app.$i18n.t('talent.humanized.date.d'),
        h: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.h.many')
            : app.$i18n.t('talent.humanized.date.h'),
        m: (count) =>
          count > 1
            ? app.$i18n.t('talent.humanized.date.m.many')
            : app.$i18n.t('talent.humanized.date.m'),
        s: () => app.$i18n.t('talent.humanized.date.moments'),
        ms: () => app.$i18n.t('talent.humanized.date.moments')
      }
    })

    if (getMonths < 12) {
      humanized = humanizeDuration(humanizing, {
        languages: { ...localizedHumanization() },
        round: true,
        largest: 1
      })
    } else {
      humanized = humanizeDuration(humanizing, {
        languages: { ...localizedHumanization() },
        round: true,
        largest: 2
      })
    }

    return humanized
  })

  inject('checkPresent', (date) => {
    if (app.$moment(date).isValid()) {
      return app.$displayTime(date, 'YYYY-MM-DD')
    } else {
      return null
    }
  })

  // array manipulations
  inject('mergeArray', (a, b, prop) => {
    const reduced = a.filter((aEl) => !b.find((bEl) => aEl[prop] === bEl[prop]))
    return reduced.concat(b)
  })

  inject('sortArray', (arr, key, order) => {
    arr.sort(function (a, b) {
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

      let comparison = 0

      if (varA > varB) {
        comparison = 1
      } else if (varA < varB) {
        comparison = -1
      }
      return order === 'desc' ? comparison * -1 : comparison
    })

    return arr
  })

  inject('manageArray', (initData, payload) => {
    let reArr = [...initData]

    switch (payload.action) {
      case 'DELETE':
        reArr.splice(payload.index, 1)
        break
      case 'EDIT':
        reArr.splice(payload.index, 1)
        reArr = [...reArr, ...payload.data]
        break
      case 'ADD':
        reArr = [...reArr, ...payload.data]
        break
      case 'EMPTY':
        reArr = []
        break
    }

    return reArr
  })

  inject('flatten', (arr) => {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? app.$flatten(toFlatten) : toFlatten)
    }, [])
  })

  // statuses
  inject('verificationStatus', (status) => {
    return store.state.auth.user?.verificationStatus === status
  })

  inject('applicationStatus', (data) => {
    switch (data) {
      case EJobApplicationStatus.SENT_TO_CLIENT:
        return {
          type: 'info',
          icon: '$vuetify.icons.mdiHelpCircle',
          text: app.$i18n.t('talent.job.application.status.s2c'),
          description: app.$i18n.t('talent.job.application.status.info.revision')
        }
      case EJobApplicationStatus.SEND_FAILED:
        return {
          type: 'info',
          icon: '$vuetify.icons.mdiHelpCircle',
          text: app.$i18n.t('talent.job.application.status.sf'),
          description: app.$i18n.t('talent.job.application.status.info.sending')
        }
      case EJobApplicationStatus.ACTIVE:
        return {
          type: 'success',
          icon: '$vuetify.icons.success',
          text: app.$i18n.t('talent.job.application.status.a'),
          description: app.$i18n.t('talent.job.application.status.info.applied')
        }
      case EJobApplicationStatus.UNCONFIRMED:
        return {
          type: 'warning',
          icon: '$vuetify.icons.mdiAlert',
          text: app.$i18n.t('talent.job.application.status.uc'),
          description: app.$i18n.t('talent.job.application.status.info.unconfirmed')
        }
      case EJobApplicationStatus.HIRED:
        return {
          type: 'success',
          icon: '$vuetify.icons.success',
          text: app.$i18n.t('talent.job.application.status.h'),
          description: app.$i18n.t('talent.job.application.status.info.hired')
        }
      case EJobApplicationStatus.REJECTED_BY_MSP:
        return {
          type: 'error',
          icon: '$vuetify.icons.error',
          text: app.$i18n.t('talent.job.application.status.rm'),
          description: app.$i18n.t('talent.job.application.status.info.rejected')
        }
      case EJobApplicationStatus.REJECTED_BY_VMS:
        return {
          type: 'error',
          icon: '$vuetify.icons.error',
          text: app.$i18n.t('talent.job.application.status.rv'),
          description: app.$i18n.t('talent.job.application.status.info.vms.rejected')
        }
      case EJobApplicationStatus.WITHDRAWN:
        return {
          type: 'warning',
          icon: '$vuetify.icons.mdiAlert',
          text: app.$i18n.t('talent.job.application.status.w'),
          description: app.$i18n.t('talent.job.application.status.info.withdrawn')
        }
      case EJobApplicationStatus.SEND_PENDING:
        return {
          type: 'info',
          icon: '$vuetify.icons.mdiHelpCircle',
          text: app.$i18n.t('talent.job.application.status.sp'),
          description: app.$i18n.t('talent.job.application.status.info.send.pending')
        }
      case EJobApplicationStatus.UNDER_CONSTRUCTION:
        return {
          type: 'info',
          icon: '$vuetify.icons.mdiProgressWrench',
          text: app.$i18n.t('talent.job.application.status.co'),
          description: app.$i18n.t('talent.job.application.status.info.under.construction')
        }
      default:
        return false
    }
  })

  // text manipulation
  inject('capitalize', (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  })

  inject('truncate', (str, num) => (str.length < num ? str : str.slice(0, num).concat('...')))

  // images/pictures/icons
  inject('displayImage', (arr, size) => {
    if (arr?.length) {
      const bannerImage = arr.find((i) => i.locale === app.$i18n.locale)
      const localeChecker = arr.some((i) => i.locale === app.$i18n.locale)
      const imageSize = size ? `/${size}` : ''

      if (arr?.length > 1 && localeChecker) {
        return `/v1/api/image/${bannerImage.uuid}${imageSize}`
      } else {
        return `/v1/api/image/${arr[0].uuid}${imageSize}`
      }
    } else {
      return app.$placeholderImage()
    }
  })

  inject('placeholderImage', () => require('@/resources/images/pool-default.svg'))

  inject('docTypeIcon', (type) => {
    const useType = type?.split('.') || []
    const fileType = useType.pop()

    if (['pdf'].includes(fileType)) {
      return require('@/resources/icons/pdf.svg')
    }

    if (['doc', 'docx'].includes(fileType)) {
      return require('@/resources/icons/word.svg')
    }
  })

  // misc
  inject('currencySign', (currency, unitType) => {
    let verb = ''

    switch (unitType) {
      case 'HR':
        verb = app.$i18n.t('talent.global.rate.unit.hr')
        break
      case 'DAY':
        verb = app.$i18n.t('talent.global.rate.unit.day')
        break
      case 'WEEK':
        verb = app.$i18n.t('talent.global.rate.unit.week')
        break
      case 'MONTH':
        verb = app.$i18n.t('talent.global.rate.unit.month')
        break
      case 'YEAR':
        verb = app.$i18n.t('talent.global.rate.unit.year')
        break
      case 'PROJECT':
        verb = app.$i18n.t('talent.global.rate.unit.project')
        break
      default:
        verb = ''
        break
    }

    return currencySign(currency, verb)
  })

  inject('paginationLength', (total, size) => {
    const divide = total / size
    return Math.ceil(divide)
  })

  inject('triggerLoader', (type, state, caption) => {
    switch (type) {
      case 'PAGE':
        if (state) {
          store.commit('PAGE_LOADER', state)
        } else {
          setTimeout(() => store.commit('PAGE_LOADER', state), 300)
        }
        break
      case 'ACTION':
        if (state) {
          store.commit('ACTION_LOADER', state)
          store.commit('ACTION_LOADER_CAPTION', caption)
        } else {
          setTimeout(() => {
            store.commit('ACTION_LOADER', state)
            store.commit('ACTION_LOADER_CAPTION', caption)
          }, 200)
        }
        break
    }
  })

  inject('resizeType', () => {
    if (window.innerWidth <= $vuetify.breakpoint.thresholds.sm) {
      return {
        mobile: true,
        desktop: false
      }
    } else {
      return {
        mobile: false,
        desktop: true
      }
    }
  })

  inject('parseCountryName', (code) => {
    if (code) {
      const getList = store.state.countriesList.find((i) => i.value === code)
      return getList.text
    }
  })

  // feature specific plugins
  inject('getSequenceString', (idx) => {
    const checkLength = idx + 1

    if (checkLength < 10) {
      return `0${checkLength}`
    } else {
      return checkLength.toString()
    }
  })

  inject('groupingAnswers', (arr) => {
    const reduced = arr.reduce((acc, e) => {
      const groupId = e.groupId

      if (acc[groupId] || (acc[groupId] = [])) {
        acc[groupId].push({
          groupId: e.groupId,
          id: e.id,
          label: e.label,
          type: e.type,
          value: e.value,
          optionLabel: e.optionLabel
        })
      }
      return acc
    }, {})

    return Object.values(reduced)
  })

  inject('clientCoreAsset', (uuid, params = {}) => {
    const baseURL = $config.clientCoreApiUrl
    const query = new URLSearchParams({ format: 'webp', ...params })

    return `${baseURL}/assets/${uuid}${query.toString() ? `?${query}` : ''}`
  })

  inject('clientMainLogo', (params = {}) =>
    app.$clientCoreAsset(store.state.clientContext.talentLogo, params)
  )

  inject('seoLogo', () => app.$clientCoreAsset(store.state.clientContext.projectLogo))

  inject('legalEntityLogo', (leName, params = {}) => {
    const legalEntities = get(store, 'state.clientContext.legalEntity', [])
    const legalEntity = legalEntities.find((le) => le.name === leName)

    // prettier-ignore
    return legalEntity?.show_logo
      ? app.$clientCoreAsset(legalEntity.logo, params)
      : undefined
  })

  inject('flagUrl', (countryCode) => `https://flagcdn.com/${countryCode.toLowerCase()}.svg`)

  inject('getSocialIcon', (value) => {
    return value.includes('linkedin')
      ? '$vuetify.icons.mdiLinkedin'
      : '$vuetify.icons.mdiLinkBoxVariant'
  })

  inject('getRedirectionCookie', async () => {
    const projectId = await app.$auth.$storage.getUniversal('projectId', true)
    const collectedProjectData = await app.$auth.$storage.getUniversal('projectData', true)

    await app.$auth.$storage.removeUniversal('projectId')

    return { projectId, collectedProjectData }
  })
}
