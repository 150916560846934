
import { EDateType } from '@/helpers/enums'

export default {
  name: 'AppHeader',

  data() {
    return {
      mobileSearchText: false,
      mobileMenu: false,
      showOnDesktop: false,
      showOnMobile: false,
      footerLinks: [],
      securityIds: []
    }
  },
  async fetch() {
    try {
      if (this.$auth.loggedIn) {
        const { data: securityIds } = await this.$api.freelancer.getFreelancerSecurityIds()
        this.securityIds = securityIds
      }

      const { data: dataLinks } = await this.$api.cms.getFooterLinks()
      this.footerLinks = dataLinks
    } catch (err) {}
  },

  computed: {
    enablePublicSearch() {
      return this.$useConfig(this.$EClientConfig.ENABLE_PUBLIC_SEARCH)
    },
    userInitials() {
      const firstName = this.$auth.user.firstName || ''
      const lastName = this.$auth.user.lastName || ''
      const initials = firstName.charAt(0) + lastName.charAt(0)
      return initials.toUpperCase()
    },
    memberSince() {
      const date = new Date(this.$auth.user.created)
      return this.$displayDate({ value: date, format: EDateType.LONG })
    },

    helpCenterLink() {
      return this.footerLinks.find((link) => link?.link_url.includes('help'))
    },
    authLinks() {
      return [
        {
          id: 'loginUser',
          name: this.$t('talent.global.header.login'),
          link: this.$link.login(),
          show: !this.$auth.loggedIn,
          tabindex: 7
        },
        {
          id: 'registerUser',
          icon: '$vuetify.icons.mdiAccountPlus',
          name: this.$t('talent.global.header.register'),
          link: this.$link.signup(),
          show: !this.$auth.loggedIn,
          tabindex: 8
        }
      ]
    },
    userMenu() {
      return [
        {
          id: 'helpFaq',
          icon: '$vuetify.icons.mdiHelpBox',
          name: this.$t('talent.global.footer.help.center'),
          link: this.helpCenterLink?.link_url,
          target: this.helpCenterLink?.link_target,
          show: this.helpCenterLink?.link_url,
          subSections: [],
          tabindex: 0
        }
      ]
    },
    userMenuMobile() {
      return [
        {
          id: 'accountSettingsMobile',
          icon: '$vuetify.icons.mdiCog',
          name: this.$t('talent.global.user.account'),
          link: '',
          show: this.$auth.loggedIn,
          target: '',
          rel: '',
          subSections: [
            {
              id: 'notificationsSettings',
              name: this.$t('talent.global.header.accountSecurity.mobile'),
              link: this.$link.account('accountSecurity'),
              show: this.$auth.loggedIn
            },
            {
              id: 'securityId',
              name: this.$t('talent.global.header.securityId.mobile'),
              link: this.$link.account('accountSecurityId'),
              show: this.$auth.loggedIn && this.securityIds.length
            },
            {
              id: 'verifiedDevices',
              name: this.$t('talent.global.header.verifiedDevices.mobile'),
              link: this.$link.account('accountVerifiedDevices'),
              show: this.$auth.loggedIn
            }
          ]
        },
        {
          id: 'helpFaq',
          icon: '$vuetify.icons.mdiHelpCircle',
          name: this.$t('talent.global.header.helpFaq.mobile'),
          link: this.helpCenterLink?.link_url,
          target: this.helpCenterLink?.link_target,
          show: this.helpCenterLink?.link_url,
          subSections: []
        }
      ]
    },

    userLinks() {
      return [
        {
          id: 'publicSearch',
          icon: '$vuetify.icons.mdiMagnify',
          name: this.$t('talent.global.header.search'),
          link: this.$link.projectSearch(),
          show: this.enablePublicSearch && !this.$auth.loggedIn,
          tabindex: 0
        },
        {
          id: 'helpFaq',
          name: this.$t('talent.global.header.helpFaq.mobile'),
          link: this.helpCenterLink?.link_url,
          target: this.helpCenterLink?.link_target,
          show: this.helpCenterLink?.link_url && !this.$auth.loggedIn,
          subSections: [],
          tabindex: 1
        },
        {
          id: 'userSearch',
          icon: '$vuetify.icons.mdiMagnify',
          name: this.$t('talent.global.header.search'),
          link: this.$link.projectSearch(),
          show: this.$auth.loggedIn,
          tabindex: 2
        },
        {
          id: 'myProjects',
          icon: '$vuetify.icons.mdiChevronDown',
          name: this.$t('talent.global.header.projects.mobile'),
          link: '',
          show: this.$auth.loggedIn,
          tabindex: 3,
          subSections: [
            {
              id: 'myApplications',
              name: `${this.$t('talent.global.header.application.mobile')} (${
                this.$auth.user?.jobApplicationCount
              })`,
              icon: '$vuetify.icons.mdiBriefcaseOutline',
              link: this.$link.projectSearch({ tab: 'applications' })
            },
            {
              id: 'myInvitations',
              name: `${this.$t('talent.global.header.invitation.mobile')} (${
                this.$auth.user?.jobInvitationCount
              })`,
              icon: '$vuetify.icons.mdiCardAccountMailOutline',
              link: this.$link.projectSearch({ tab: 'invitations' })
            }
          ]
        },
        {
          id: 'userAcocunt',
          name: this.$t('talent.global.user.menu.profile'),
          link: this.$link.profile(),
          show: this.$auth.loggedIn,
          tabindex: 4
        },
        {
          id: 'pools',
          name: this.$t('talent.global.user.menu.pools'),
          link: this.$link.pools(),
          show: this.$auth.loggedIn,
          tabindex: 5
        },
        {
          id: 'settings',
          icon: '$vuetify.icons.mdiChevronDown',
          name: this.$t('talent.account.title'),
          link: '',
          show: this.$auth.loggedIn,
          tabindex: 6,
          subSections: [
            {
              id: 'notificationsSettings',
              name: this.$t('talent.global.header.notificationsSettings.mobile'),
              icon: '$vuetify.icons.mdiBellOutline',
              link: this.$link.account('accountNotifications')
            },
            {
              id: 'accountAndSecurity',
              name: this.$t('talent.account.title.security'),
              icon: '$vuetify.icons.mdiSecurity',
              link: this.$link.account('accountSecurity')
            },
            {
              id: 'verifiedDevices',
              name: this.$t('talent.global.header.verifiedDevices.mobile'),
              icon: '$vuetify.icons.mdiCellphoneLink',
              link: this.$link.account('accountVerifiedDevices')
            }
          ]
        }
      ]
    },
    mobileUserLinks() {
      return [
        {
          id: 'publicSearch',
          icon: '$vuetify.icons.mdiMagnify',
          name: this.$t('talent.global.header.search.mobile'),
          link: this.$link.projectSearch(),
          show: this.enablePublicSearch && !this.$auth.loggedIn,
          subSections: [],
          tabindex: 0
        },
        {
          id: 'userSearch',
          icon: '$vuetify.icons.mdiMagnify',
          name: this.$t('talent.global.header.search.mobile'),
          link: this.$link.projectSearch(),
          show: this.$auth.loggedIn,
          subSections: [],
          tabindex: 1
        },
        {
          id: 'myProjects',
          icon: '$vuetify.icons.suitcase',
          name: this.$t('talent.global.header.projects.mobile'),
          link: '',
          show: this.$auth.loggedIn,
          tabindex: 2,
          subSections: [
            {
              id: 'myApplications',
              name: `${this.$t('talent.global.header.application.mobile')} (${
                this.$auth.user?.jobApplicationCount
              })`,
              link: this.$link.projectSearch({ tab: 'applications', page: 1 })
            },
            {
              id: 'myInvitations',
              name: `${this.$t('talent.global.header.invitation.mobile')} (${
                this.$auth.user?.jobInvitationCount
              })`,
              link: this.$link.projectSearch({ tab: 'invitations' })
            }
          ]
        },
        {
          id: 'userAccount',
          icon: '$vuetify.icons.mdiAccount',
          name: this.$t('talent.global.user.menu.profile'),
          link: this.$link.profile(),
          show: this.$auth.loggedIn,
          subSections: [],
          tabindex: 3
        },
        {
          id: 'pools',
          icon: '$vuetify.icons.mdiAccountGroup',
          name: this.$t('talent.global.user.menu.pools'),
          link: this.$link.pools(),
          show: this.$auth.loggedIn,
          subSections: [],
          tabindex: 4
        },
        {
          id: 'notificationsSettings',
          icon: '$vuetify.icons.bell',
          name: this.$t('talent.account.title.notification.settings'),
          link: this.$link.account('accountNotifications'),
          show: this.$auth.loggedIn,
          subSections: [],
          tabindex: 5
        }
      ]
    }
  },

  mounted() {
    this.resizeCallback()
  },

  methods: {
    resizeCallback() {
      const sizeType = this.$resizeType()
      this.mobileSearchText = sizeType.mobile
      this.showOnMobile = sizeType.mobile
      this.showOnDesktop = sizeType.desktop
    },

    logout() {
      if (this.$route.query.token) {
        const query = Object.assign({}, this.$route.query)
        delete query.token
        this.$router.push({ query })
      }
      this.$auth.logout('local')
    }
  }
}
