// eslint camelcase: off

import { extend } from 'vee-validate'
import { parsePhoneNumber } from 'awesome-phonenumber'
import {
  required,
  email,
  alpha,
  min,
  max,
  confirmed,
  numeric,
  oneOf,
  regex,
  integer,
  size,
  ext,
  between,
  min_value as minValue
} from 'vee-validate/dist/rules'

export default function ({ app, $t }) {
  extend('required', {
    ...required
  })

  extend('email', {
    ...email
  })

  extend('regex', {
    ...regex
  })

  extend('alpha', {
    ...alpha
  })

  extend('min', {
    ...min
  })

  extend('max', {
    ...max
  })

  extend('confirmed', {
    ...confirmed
  })

  extend('numeric', {
    ...numeric
  })

  extend('oneOf', {
    ...oneOf
  })

  extend('integer', {
    ...integer
  })

  extend('size', {
    ...size
  })

  extend('ext', {
    ...ext
  })

  extend('between', {
    ...between
  })

  extend('minValue', {
    ...minValue
  })

  extend('specialChars', {
    validate: (email) => {
      const pattern = /^[a-zA-Z0-9@.-_]+$/
      return pattern.test(email)
    },
    message: $t('talent.register.email.validation.special.chars')
  })

  extend('validPhone', {
    validate: (value) => {
      return (
        typeof value === 'object' &&
        parsePhoneNumber(value.number, { regionCode: value.countryCode }).valid
      )
    },
    message: $t('talent.message.error.invalid.phone.number')
  })

  extend('uniqueEmail', {
    validate: async (value) => {
      try {
        const { data } = await app.$api.freelancer.isEmailInUse(value)
        return !data.existingUser
      } catch (err) {
        return false
      }
    },
    message: $t('talent.register.email.in.use')
  })
}
