
import { mapGetters } from 'vuex'

export default {
  name: 'LoaderPage',

  data() {
    return {
      clientPrimaryColor: ''
    }
  },

  async fetch() {
    this.clientPrimaryColor = await this.$vuetify.theme.themes.light.primary
  },

  computed: {
    ...mapGetters({
      pageLoader: 'pageLoader',
      actionLoader: 'actionLoader',
      actionLoaderCaption: 'actionLoaderCaption'
    })
  }
}
