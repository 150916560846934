export default (axios) => ({
  updateFreelancer(payload) {
    return axios.put('/v1/api/freelancer', payload)
  },

  resetPassword(payload) {
    return axios.put('/v1/api/freelancer/resetPassword', payload)
  },

  resetPhoneCode(payload) {
    return axios.put('/v1/api/freelancer/resetPhoneCode', payload)
  },

  resetForgottenPasswordWithToken(payload, query) {
    return axios.put('/v1/api/freelancer/resetForgotPassword', payload, {
      params: {
        ...query
      }
    })
  },

  resetForgottenPassword(payload) {
    return axios.post('/v1/api/forgotPassword/send', payload)
  },

  updateSubscriptions(payload) {
    return axios.put('/v1/api/freelancer/clientSubscription', payload)
  },

  updateSubscriptionsByUuid(uuid, payload) {
    return axios.put(`/v1/api/freelancer/clientSubscription/${uuid}`, payload)
  },

  setFreelancerLocale(payload) {
    return axios.post('/v1/api/freelancer/locale', payload)
  },

  updateResidenceCountry(payload) {
    return axios.put('/v1/api/freelancer/updateResidenceCountry', payload)
  },

  updateFreelancerProfileInfo(payload) {
    return axios.put('/v1/api/freelancer/updateProfileInfo', payload)
  },

  getProfileStrength() {
    return axios.get('/v1/api/freelancer/strength')
  },

  updateTalentCVFile(payload) {
    return axios.put('/v1/api/freelancer/cv/pdf', payload)
  },

  deleteTalentCVFile(deleteUUID) {
    return axios.delete(`/v1/api/freelancer/cv/pdf/${deleteUUID}`)
  },

  getAllCVResources() {
    return axios.get('/v1/api/freelancer/cv/resources')
  },

  activateFreelancer(query) {
    return axios.get('/v1/api/freelancer/activate', {
      params: {
        ...query
      },
      blockInterceptions: true
    })
  },

  verifyEmailAsAlternative(query) {
    return axios.get('/v1/api/freelancer/verifyEmail', {
      params: {
        ...query
      }
    })
  },

  registerFreelancer(payload) {
    return axios.post('/v1/api/freelancer/register', payload)
  },
  registerFromPhone(payload) {
    return axios.post('/v1/api/freelancer/registerFromPhone', payload)
  },

  registerFromLinkendin(payload) {
    return axios.post('/v1/api/freelancer/registerFromLinkedIn', payload)
  },

  registerFromGoogle(payload) {
    return axios.post('/v1/api/freelancer/registerFromGoogle', payload)
  },

  registerFromAuth0(payload) {
    return axios.post('/v1/api/freelancer/registerFromAuth0', payload)
  },

  fillMissingRegistrationData(payload) {
    return axios.post('/v1/api/freelancer/fillMissingRegistrationData', payload)
  },

  getFreelancerCV() {
    return axios.get('/v1/api/freelancer/cv')
  },

  hasPassword() {
    return axios.get('/v1/api/freelancer/hasPassword')
  },

  sendActivationEmail() {
    return axios.get('/v1/api/freelancer/sendActivationEmail')
  },

  getFreelancerNotAvailableRecords() {
    return axios.get('/v1/api/freelancer/notAvailable')
  },

  updateFreelancerNotAvailableRecords(recordId, payload) {
    return axios.put(`/v1/api/freelancer/notAvailable/${recordId}`, payload)
  },

  addFreelancerNotAvailableRecords(payload) {
    return axios.post('/v1/api/freelancer/notAvailable', payload)
  },

  deleteFreelancerNotAvailableRecords(recordId) {
    return axios.delete(`/v1/api/freelancer/notAvailable/${recordId}`)
  },

  setFreelancerReferral(payload) {
    return axios.post('/v1/api/freelancer/referral', payload)
  },

  getFreelancerSecurityIds() {
    return axios.get('/v1/api/freelancer/securityIds')
  },

  setFreelancerSecurityId(payload) {
    return axios.post('/v1/api/freelancer/securityId', payload)
  },

  getMissingLegalAgreements() {
    return axios.get('/v1/api/freelancer/legal/needsToAgree')
  },

  agreeToUpdatedPolicies(query) {
    return axios.post('/v1/api/freelancer/legal/agree', null, { params: { ...query } })
  },

  deactivateAccount() {
    return axios.post('/v1/api/freelancer/tosDeactivate')
  },

  isEmailInUse(email) {
    return axios.post('/v1/api/freelancer/email/exists', { email })
  },

  isMobileInUse(payload) {
    return axios.post('/v1/api/freelancer/phone/exists', payload)
  },

  phoneCodeExpiry(payload) {
    return axios.post('/v1/api/freelancer/phoneCode/expiry', payload)
  },

  isUserDeviceTrusted(uuid) {
    return axios.get(`/v1/api/userDevices/${uuid}/isTrusted`)
  },

  saveUserDevice(uuid, payload) {
    return axios.post(`/v1/api/userDevices/${uuid}`, payload)
  },

  getVerifiedDevices() {
    return axios.get('/v1/api/userDevices')
  },

  deleteDevice(uuid) {
    return axios.delete(`/v1/api/userDevices/${uuid}`)
  },

  getProfile() {
    return axios.get('/v1/api/flexOnboarding/profile')
  },

  updateProfile(payload) {
    return axios.patch('/v1/api/flexOnboarding/profile', payload)
  },

  updateRate(params) {
    return axios.put('/v1/api/freelancer/rate', null, {
      params: { ...params }
    })
  },

  getRate() {
    return axios.get('/v1/api/freelancer/rate')
  }
})
