export const state = () => ({
  categoriesMenu: [],
  poolsMenu: [],
  citiesMenu: [],
  allCitiesInPools: [],
  allCategories: [],
  allPools: [],
  allCities: []
})

export const actions = {
  // TODO: TWAGOS-2207 adjusted api call implementation is needed here
  async getBreadcrumbs({ commit, dispatch }) {
    const { data } = await this.$api.utils.getBreadcrumbs()
    const getPools = data.poolBreadcrumbs
    const getCities = data.locationBreadcrumbs
    const hasCategories = getPools.some((pool) => typeof pool.category_id === 'number')

    if (hasCategories) {
      try {
        const requestFields = [
          'id',
          'default_image',
          'slug',
          'translations.language_code',
          'translations.name',
          'translations.image',
          'translations.description'
        ]

        const { data: allCategories } = await this.$api.cms.getCategories(requestFields)
        await commit('ALL_CATEGORIES', allCategories)
      } catch (error) {
        console.error(error)
      }
    }

    await commit('ALL_POOLS', getPools)
    await commit('ALL_CITIES', getCities)
    await dispatch('linksMenu')
  },

  async linksMenu({ state, commit }) {
    const splitedCategories = []
    const splitedPools = []
    const splitedCities = []
    const splitedCitiesInPools = []
    const columnSize = 3
    const categoriesColumns = [...state.allCategories]
    const poolsColumns = [...state.allPools]
    const citiesColumns = [...state.allCities]
    const createCitiesInPools = [...state.allPools]
    const citiesInPools = createCitiesInPools.map((item) => {
      if (item.poolCities.length && item.jobsCount > 0) {
        return item.poolCities.map((cities) => {
          return {
            poolTitle: item.poolNameVerbiage,
            poolId: item.id,
            cityName: cities
          }
        })
      }

      return null
    })

    const rawCitiesInPools = this.$flatten(citiesInPools)
    const citiesInPoolsColumns = rawCitiesInPools.filter((el) => el != null)

    if (categoriesColumns.length) {
      do {
        splitedCategories.push(categoriesColumns.splice(0, columnSize))
      } while (categoriesColumns.length > 0)
      await commit('FILL_CATEGORIES_MENU', splitedCategories)
    }

    if (poolsColumns.length) {
      do {
        splitedPools.push(poolsColumns.splice(0, columnSize))
      } while (poolsColumns.length > 0)
      await commit('FILL_POOLS_MENU', splitedPools)
    }

    if (citiesColumns.length) {
      do {
        splitedCities.push(citiesColumns.splice(0, columnSize))
      } while (citiesColumns.length > 0)
      await commit('FILL_CITIES_MENU', splitedCities)
    }

    if (citiesInPoolsColumns.length) {
      do {
        splitedCitiesInPools.push(citiesInPoolsColumns.splice(0, columnSize))
      } while (citiesInPoolsColumns.length > 0)
      await commit('ALL_CITIES_IN_POOLS', splitedCitiesInPools)
    }
  }
}

export const mutations = {
  FILL_CATEGORIES_MENU: (state, payload) => {
    state.categoriesMenu = payload
  },

  FILL_POOLS_MENU: (state, payload) => {
    state.poolsMenu = payload
  },

  FILL_CITIES_MENU: (state, payload) => {
    state.citiesMenu = payload
  },

  ALL_CATEGORIES: (state, payload) => {
    state.allCategories = payload
  },

  ALL_POOLS: (state, payload) => {
    state.allPools = payload
  },

  ALL_CITIES: (state, payload) => {
    state.allCities = payload
  },

  ALL_CITIES_IN_POOLS: (state, payload) => {
    state.allCitiesInPools = payload
  }
}

export const getters = {
  categoriesMenu: (state) => {
    return state.categoriesMenu
  },

  poolsMenu: (state) => {
    return state.poolsMenu
  },

  citiesMenu: (state) => {
    return state.citiesMenu
  },

  allCategories: (state) => {
    return state.allCategories
  },

  allPools: (state) => {
    return state.allPools
  },

  allCities: (state) => {
    return state.allCities
  },

  allCitiesInPools: (state) => {
    return state.allCitiesInPools
  }
}
