
export default {
  name: 'Modal',

  props: {
    modalOpen: Boolean,
    hideToolbar: Boolean,
    isFullscreen: Boolean,
    hasFullScreen: {
      type: Boolean,
      default: true,
      required: false
    },
    canClose: {
      type: Boolean,
      default: true,
      required: false
    },
    hasCancel: {
      type: Boolean,
      default: true,
      required: false
    },
    showActions: {
      type: Boolean,
      default: true,
      required: false
    },
    modalTitle: {
      type: String,
      default: '',
      required: false
    },
    backgroundColor: {
      type: String,
      default: 'white',
      required: false
    },
    toolbarColor: {
      type: String,
      default: 'grey lighten-4',
      required: false
    },
    maxWidth: {
      type: [String, Number],
      default: '680',
      required: false
    },
    modalCancelBtnId: {
      type: String,
      default: 'modalCancel'
    },
    modalCancelBtnText: {
      type: String,
      default: 'talent.global.button.cancel'
    },
    modalCloseBtnId: {
      type: String,
      default: 'modalClose'
    },
    skipLoader: Boolean
  },

  data() {
    return {
      isLoading: true
    }
  },

  computed: {
    dialog: {
      get() {
        return this.modalOpen
      },
      set(value) {
        this.$emit('update:modal-open', value)
      }
    },

    fullscreen() {
      if (this.isFullscreen) {
        return true
      } else {
        return this.hasFullScreen && this.$vuetify.breakpoint.smAndDown
      }
    },

    modalTransition() {
      if (this.isFullscreen) {
        return 'dialog-bottom-transition'
      } else {
        return this.hasFullScreen && this.$vuetify.breakpoint.smAndDown
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      }
    },

    modalHeight() {
      if (this.isFullscreen) {
        return 'height: 100%;'
      } else if (!this.$vuetify.breakpoint.smAndDown) {
        return 'max-height: 80vh;'
      } else if (!this.hasFullScreen) {
        return ''
      } else {
        return 'height: 100vh;'
      }
    }
  },

  watch: {
    modalOpen: {
      immediate: true,
      handler(val) {
        if (this.skipLoader) {
          this.isLoading = false
        } else if (val) {
          setTimeout(() => (this.isLoading = false), 900)
        } else {
          this.isLoading = true
        }
      }
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false
    }
  }
}
