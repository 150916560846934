import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

export default ({ app }, inject) => {
  inject('intlTelInput', (input, options = {}) => {
    try {
      return intlTelInput(input, {
        loadUtils: () => import('intl-tel-input/build/js/utils.js'),
        ...options
      })
    } catch (error) {
      throw new Error('Error initializing intl-tel-input: ' + error.message)
    }
  })
}
