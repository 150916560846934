export default (axios) => ({
  getPoolMembership() {
    return axios.get('/v1/api/pool/membership')
  },

  togglePoolMembershipNotifications(membershipId) {
    return axios.post(`/v1/api/pool/membership/${membershipId}/switchNotification`)
  },

  applyForPoolMembership(poolId) {
    return axios.post(`/v1/api/pool/${poolId}/membership/apply`)
  },

  getPoolAcceptedMembership() {
    return axios.get('/v1/api/pool/membership/accepted')
  }
})
