export default (axios) => ({
  getProjectOptions(jobHandleId) {
    return axios.get(`/v1/api/project/${jobHandleId}/options`, { blockInterceptions: true })
  },

  getProjectApplications(query) {
    return axios.get('/v1/api/projectApplication', {
      params: {
        ...query
      }
    })
  },

  getProjectApplicationsByPool(poolId, query) {
    return axios.get(`/v1/api/pool/${poolId}/projectApplications`, {
      params: {
        ...query
      }
    })
  },

  getProjectApplicationSteps(jobHandleId) {
    return axios.get(`/v1/api/projectApplication/${jobHandleId}/flowsteps`)
  },

  applyForProject(payload, query) {
    return axios.post('/v1/api/projectApplication', payload, {
      params: {
        ...query
      }
    })
  },

  getIncompleteJobApplications() {
    return axios.get('/v1/api/projectApplication/drafts')
  },

  updateProjectApplication(applicationId, payload) {
    return axios.put(`/v1/api/projectApplication/${applicationId}`, payload)
  },

  withdrawJobApplication(applicationId) {
    return axios.put(`/v1/api/projectApplication/${applicationId}/withdraw`)
  },

  getInterviewSlots(jobHandleId) {
    return axios.get(`/v1/api/projectApplication/${jobHandleId}/calendar/freebusy`)
  },

  agreeToPoliciesByJobApplicationId(applicationId, query) {
    return axios.post(
      `/v1/api/projectApplication/${applicationId}/legal/agree?terms=${query.terms}&privacy=${query.privacy}`
    )
  },

  getProjectApplication(applicationId) {
    return axios.get(`/v1/api/projectApplication/${applicationId}/load`)
  },

  getAssessments(applicationId) {
    return axios.get(`/v1/api/projectApplication/${applicationId}/assessment`)
  }
})
