import colors from 'vuetify/lib/util/colors'

const commonColours = {
  black: '#353839',
  error: '#B71C1C',
  info: '#01579B',
  grey: {
    ...colors.grey
  }
}

export function clientTheme({ clientContext }) {
  return {
    locales: clientContext.languages.map(({ iso }) => iso),
    light: {
      primary: clientContext.primaryColor,
      ...commonColours
    }
  }
}
