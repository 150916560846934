export default async function ({ app, $api, $auth, store, redirect, error }) {
  if ($auth.loggedIn && $auth.user.hasCompletedOnboarding) {
    return redirect({ name: 'app-project' })
  } else {
    try {
      const { data: defineOnboarding } = await $api.flow.getOnboarding()
      await store.dispatch('onboarding/createStructure', defineOnboarding)
    } catch (err) {
      error({
        statusCode: err.response?.status,
        message: err.response?.statusText
      })
    }
  }
}
