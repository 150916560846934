
import { ToastPanel } from '@twago/toast'

export default {
  name: 'Empty',

  components: { ToastPanel },

  head() {
    return this.$clientI18nSeo()
  },

  watch: {
    '$nuxt.isFetching': {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$triggerLoader('PAGE', false)
        }
      }
    },

    '$route.path': {
      immediate: true,
      handler() {
        this.$triggerLoader('PAGE', true)
      }
    }
  },

  beforeDestroy() {
    this.$toast.show = false
  }
}
