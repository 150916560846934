
export default {
  name: 'AppBanner',

  data: () => ({
    showModal: false
  }),

  computed: {
    verificationText() {
      let bannerString = ''

      if (this.$verificationStatus('UV') && !this.$auth.user.futureEmail) {
        bannerString = this.$t('talent.global.user.verify.account.text', {
          email: this.$auth.user.email
        })
      } else if (this.$auth.user.futureEmail) {
        bannerString = this.$t('talent.global.user.verify.email.text', {
          email: this.$auth.user.futureEmail
        })
      }

      return bannerString
    }
  },

  methods: {
    showActivationModal() {
      this.showModal = !this.showModal
      this.$pushEvent('EmailNotReceived', { talentId: this.$auth.user.id })
    },

    changeEmail() {
      this.showModal = !this.showModal

      this.$pushEvent('changeEmailActivationBanner', { talentId: this.$auth.user?.id })
    },

    resend() {
      // prettier-ignore
      return this.resendVerification()
    },

    async resendVerification() {
      let responseCode

      try {
        const { status } = await this.$api.freelancer.sendActivationEmail()

        this.$toast.fire(this.$t('talent.global.user.verify.account.sent'))
        responseCode = status
      } catch (err) {
        responseCode = err.response?.status
      } finally {
        this.showModal = !this.showModal

        this.$pushEvent('resendActivationEmail', {
          responseCode,
          talentId: this.$auth.user?.id
        })
      }
    },

    async resendChangeEmail() {
      let responseCode

      try {
        const { status } = await this.$api.login.changeEmail({
          email: this.$auth.user.futureEmail,
          resendEmail: true
        })

        this.$toast.fire(this.$t('talent.global.user.resend.change.email'))
        responseCode = status
      } catch (err) {
        responseCode = err.response?.status
      } finally {
        this.showModal = !this.showModal

        this.$pushEvent('resendActivationEmail', {
          responseCode,
          talentId: this.$auth.user?.id
        })
      }
    }
  }
}
