import { omit } from 'lodash-es'
import { getCleanedJobTitle } from '@twago/configs'
import { EFilter } from '@/helpers/enums'
import { appRedirect } from '@/helpers/router'

export default (ctx, inject) => {
  const { app } = ctx

  // queries manager
  inject('querySelectionArray', (list) => (list ? list.split(',')?.map(Number) : []))

  inject('updateQuery', (query, newQuery, updatePager = true) => {
    const defaultPager = updatePager ? { [EFilter.PAGE]: 1 } : {}
    const payload = {
      query: {
        ...query,
        ...newQuery,
        ...defaultPager
      }
    }

    return appRedirect(ctx, payload)
  })

  inject('cleanQuery', (query, clean) => {
    const payload = {
      query: {
        ...omit(query, clean),
        [EFilter.PAGE]: 1
      }
    }

    return appRedirect(ctx, payload)
  })

  inject('resetPage', (query) => {
    const payload = {
      query: {
        ...query,
        [EFilter.PAGE]: 1
      }
    }

    return appRedirect(ctx, payload)
  })

  inject('prevPage', (query) => {
    return app.router.push({
      name: app.$auth.loggedIn ? 'app-project' : 'projects',
      query: { ...query }
    })
  })

  inject('footerLinks', [
    {
      id: 'termsLink',
      name: app.$i18n.t('talent.global.footer.terms'),
      class: 'mr-6 my-2',
      link: '/terms',
      target: '_blank'
    },
    {
      id: 'privacyLink',
      name: app.$i18n.t('talent.global.footer.privacy'),
      class: 'mr-6 my-2',
      link: '/privacy',
      target: '_blank'
    },
    {
      id: 'sitempLink',
      name: app.$i18n.t('talent.global.footer.sitemap'),
      class: 'mr-6 my-2',
      link: '/sitemap.xml',
      target: '_blank'
    }
  ])

  function resolveRoute(routeObject) {
    const resolved = app.router.resolve(routeObject)
    return resolved ? resolved.route.redirectedFrom || resolved.route.fullPath : ''
  }

  inject('link', {
    account: (tab) =>
      resolveRoute({
        name: 'app-account',
        query: tab ? { tab } : {}
      }),

    accountCreatePassword: (query) =>
      resolveRoute({
        name: 'app-account-create-password',
        query
      }),

    accountSocialIncomplete: () => resolveRoute({ name: 'app-account-social-incomplete' }),

    application: (projectId) =>
      resolveRoute({
        name: 'app-project-id-apply',
        params: { id: projectId }
      }),

    category: (id, title) =>
      resolveRoute({
        name: 'projects-categories-categoryName-id',
        params: {
          categoryName: getCleanedJobTitle(title),
          id
        }
      }),

    forgotPassword: () => resolveRoute({ name: 'app-forgot-password' }),

    location: (location) =>
      resolveRoute({
        name: 'projects-in-city',
        params: { city: getCleanedJobTitle(location) }
      }),

    login: (query) => resolveRoute({ name: 'app-login', query: { ...query } }),

    onboarding: (slug, query) =>
      slug
        ? resolveRoute({ name: 'app-onboarding-slug', params: { slug }, query })
        : resolveRoute({ name: 'app-onboarding', query }),

    pool: (id, title) => {
      if (title || !app.$auth.loggedIn) {
        return resolveRoute({
          name: 'projects-pool-poolName-id',
          params: {
            poolName: getCleanedJobTitle(title) || '-',
            id
          }
        })
      }

      return resolveRoute({
        name: 'app-pool-id',
        params: { id }
      })
    },

    poolInLocation: ({ id, title, city }) =>
      resolveRoute({
        name: 'projects-pool-poolName-id-city',
        params: {
          id,
          city: getCleanedJobTitle(city),
          poolName: getCleanedJobTitle(title)
        }
      }),

    pools: () => resolveRoute({ name: 'app-pool' }),

    profile: (tab) =>
      resolveRoute({
        name: 'app-profile',
        query: tab ? { tab } : {}
      }),

    project: ({ title, id }, query) =>
      resolveRoute({
        name: app.$auth.loggedIn ? 'app-project-id' : 'projects-title-id',
        params: {
          title: title ? getCleanedJobTitle(title) : undefined,
          id
        },
        query: query?.query || query
      }),

    projectSearch: (query) =>
      resolveRoute({
        name:
          app.$useConfig(app.$EClientConfig.ENABLE_PUBLIC_SEARCH) && !app.$auth.loggedIn
            ? 'projects'
            : 'app-project',
        query
      }),

    signup: () => resolveRoute({ name: 'app-signup' }),

    socialCallback: (query) => resolveRoute({ name: 'social-callback', query })
  })
}
