export default (axios) => ({
  getProject(jobHandleId) {
    return axios.get(`/v1/api/project/${jobHandleId}`, { blockInterceptions: true })
  },

  getJobsLocations(query) {
    return axios.get('/v1/api/project/siteLocations', {
      params: {
        ...query
      }
    })
  },

  excludeJobNotification(payload) {
    return axios.post('/v1/api/project/exclude', payload)
  },

  removeJobNotificationExclusion(exclusionId) {
    return axios.post(`/v1/api/project/exclude/${exclusionId}`)
  },

  getProjectDetailsById(jobHandleId) {
    return axios.get(`/v1/api/project/${jobHandleId}/pageOpen`, { blockInterceptions: true })
  },

  getJobNotificationExclusions() {
    return axios.get('/v1/api/project/listExclusions')
  },

  getSecurityIdFaqVerbiage(jobHandleId) {
    return axios.get(`/v1/api/project/${jobHandleId}/securityIdFaqVerbiage`)
  },

  getSecurityIdPattern(jobHandleId) {
    return axios.get(`/v1/api/project/${jobHandleId}/securityIdPattern`)
  },

  getCalculatedRates(jobHandleId, query) {
    return axios.get(`/v1/api/project/${jobHandleId}/apply/rates/calculate`, {
      params: {
        ...query
      }
    })
  },

  getAppFlow(projectId) {
    return axios.get(`/v1/api/project/${projectId}/applicationFlow`)
  },

  updateAppFlow(projectId, payload, query) {
    return axios.patch(`/v1/api/project/${projectId}/applicationFlow`, payload, {
      params: { ...query }
    })
  },

  cancelAppFlow(projectId) {
    return axios.delete(`/v1/api/project/${projectId}/applicationFlow`)
  },

  finishAppFlow(projectId) {
    return axios.post(`/v1/api/project/${projectId}/finishFlow`)
  }
})
