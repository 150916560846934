import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=1bf86f4b&"
import script from "./empty.vue?vue&type=script&lang=js&"
export * from "./empty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loaders: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/loaders.vue').default,AppTermsExpired: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appTermsExpired.vue').default,AppAnnouncement: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appAnnouncement.vue').default})
