import Cookies from 'js-cookie'
import { forwardModel } from '@twago/configs'
import { makeErrorHandler, makeSuccessHandler } from '@twago/axios-intercept'

export default function ({ app, $axios, req, store, redirect, error, $config, isDev }) {
  const affiliateSession = Cookies.get('_affiliate-session_')
  const xFwd = forwardModel({ req, $config, isDev })

  if (xFwd) {
    $axios.defaults.headers.common['x-forwarded-host'] = xFwd
  }

  if (affiliateSession) {
    $axios.setHeader('talentpool-affiliate', affiliateSession)
  }

  const { clientName } = store.state.clientContext
  const defaultErrorMsg = app.$i18n.t('talent.message.generic.error')

  const handleSuccess = makeSuccessHandler({ clientName })
  const handleError = makeErrorHandler({
    clientName,
    onErrorHandled,
    redirectToLogin,
    showErrorPage,
    showErrorToast
  })

  function onErrorHandled() {
    app.$triggerLoader('ACTION', false)
    app.$triggerLoader('PAGE', false)
  }

  function redirectToLogin() {
    redirect(app.$link.login())
  }

  function showErrorPage(statusCode = 500, message = defaultErrorMsg) {
    error({ statusCode, message })
  }

  function showErrorToast(data, config = {}) {
    const toastConf = { type: 'error', ...config }
    const beErrorMessages = data?.messages || []
    const toastMsg =
      Array.isArray(beErrorMessages) && beErrorMessages.length
        ? beErrorMessages.map((message) => app.$i18n.t(message))
        : defaultErrorMsg

    app.$toast.fire(toastMsg, toastConf)
  }

  $axios.interceptors.response.use(handleSuccess, handleError)
}
