import { EClientConfig } from '@/helpers/enums'
export const state = () => ({
  mobileCode: '',
  mobileCallingCode: '',
  mobileNumber: '',
  isRegistered: false
})

export const mutations = {
  MOBILE_CODE: (state, payload) => {
    state.mobileCode = payload
  },
  MOBILE_CALLING_CODE: (state, payload) => {
    state.mobileCallingCode = payload
  },
  MOBILE_NUMBER: (state, payload) => {
    state.mobileNumber = payload
  },
  IS_REGISTERED: (state, payload) => {
    state.isRegistered = payload
  }
}

export const getters = {
  mobileCountriesSettings: (state, getters, rootState) => {
    const config = rootState.clientConfig?.find(
      (config) => config?.configKey === EClientConfig.MOBILE_COUNTRIES_SETTINGS
    )
    return config?.configValue
  },
  mobileCode: (state) => {
    return state.mobileCode
  },
  mobileCallingCode: (state) => {
    return state.mobileCallingCode
  },
  mobileNumber: (state) => {
    return state.mobileNumber
  },
  isRegistered: (state) => {
    return state.isRegistered
  },
  countriesCodeList: (state, getters, rootState) => {
    const { excludedList = [], excludedEnabled } = getters.mobileCountriesSettings || {}
    const excludedListLowercase = new Set(
      excludedList.map((excludedCountry) => excludedCountry.toLowerCase())
    )

    const countryCodes = rootState.countriesList.map((country) => country?.value?.toLowerCase())

    return excludedEnabled
      ? countryCodes.filter((countryCode) => !excludedListLowercase.has(countryCode))
      : countryCodes
  },
  mobileCountriesSettingsPrefList: (state, getters) => {
    return getters.mobileCountriesSettings?.prefEnabled
      ? getters.mobileCountriesSettings?.prefList
      : []
  }
}
