export default (axios) => ({
  authorize(payload) {
    return axios.post('/v1/api/authorize', payload, { blockInterceptions: true })
  },

  authorizeWithUrlToken(payload) {
    return axios.post('/v1/api/authorizeWithUrlToken', payload, { blockInterceptions: true })
  },

  authorizeWithLinkedInToken(payload) {
    return axios.post('/v1/api/authorizeWithLinkedInToken', payload)
  },

  authorizeWithOtlToken(payload) {
    return axios.post('/v1/api/authorizeWithOtlToken', payload, { blockInterceptions: true })
  },

  authorizeWithGoogleToken(payload) {
    return axios.post('/v1/api/authorizeWithGoogleToken', payload)
  },

  authorizeWithAuth0Token(payload) {
    return axios.post('/v1/api/authorizeWithAuth0Token', payload)
  },

  authorizeWithMobileToken(payload) {
    return axios.post('/v1/api/authorizeWithMobileToken', payload, { blockInterceptions: true })
  },

  refreshToken(config) {
    return axios.post('/v1/api/refreshToken', null, config)
  },

  changeEmail(payload) {
    return axios.post('/v1/api/freelancer/changeEmail', payload)
  },

  resendVerificationLink() {
    console.log('calling api')
    return axios.get('/v1/api/freelancer/resendEmailVerification')
  },

  updateEmail(query) {
    return axios.post('/v1/api/freelancer/confirmUpdatedEmail', {
      params: {
        ...query
      }
    })
  },

  getOneTimeLinkLogin(payload) {
    return axios.post('/v1/api/oneTimeLinkLogin', payload, {
      blockInterceptions: true
    })
  }
})
