
import { mapGetters } from 'vuex'

export default {
  name: 'PublicLinksBlock',

  computed: {
    ...mapGetters({
      categoriesMenu: 'publicSearch/categoriesMenu',
      poolsMenu: 'publicSearch/poolsMenu',
      citiesMenu: 'publicSearch/citiesMenu',
      allCitiesInPools: 'publicSearch/allCitiesInPools'
    }),

    poolColumns() {
      return [...this.poolsMenu]
    },

    citiesColumns() {
      return [...this.citiesMenu]
    },

    citiesInPoolsColumns() {
      return [...this.allCitiesInPools]
    }
  },

  methods: {
    poolInLocationLink(data) {
      return this.$link.poolInLocation({
        id: data.poolId,
        title: this.$t(data.poolTitle),
        city: data.cityName
      })
    }
  }
}
