export default (axios) => ({
  uploadResource(file, fileName, headers = {}, query = {}) {
    const formData = new FormData()

    if (fileName) {
      formData.append('resource', file, fileName)
    } else {
      formData.append('resource', file)
    }

    if (headers['Content-Type']) {
      headers['Content-Type'] = `multipart/form-data; boundary=${formData._boundary}`
    } else {
      headers['Content-Type'] = `multipart/form-data`
    }

    return axios.post(`/v1/api/resource`, formData, {
      headers,
      params: {
        ...query
      }
    })
  },

  getMetaBinaryData(uuid) {
    return axios.get(`/napi/resource/${uuid}/meta`)
  }
})
