import { EProjectStatus } from '@/helpers/enums'

export default ({ req, app, store }, inject) => {
  const clientName = store.state.clientContext.name
  const host = req ? req.headers.host : window.location.host

  inject('pushEvent', async (event, payload) => {
    await app.$gtm.push({
      event,
      clientName,
      ...payload
    })
  })

  inject('flowClickEvt', async ({ event, stepName, talentId, componentType = undefined }) => {
    await app.$gtm.push({
      event,
      clientName,
      stepName,
      talentId,
      componentType
    })
  })

  inject(
    'flexOnBoardingStartedOrCompleted',
    async ({ event, clientName, talentId, onboardingType }) => {
      await app.$gtm.push({
        event,
        clientName,
        talentId,
        onboardingType
      })
    }
  )

  inject('pushJobEvent', async (event, data) => {
    // prettier-ignore
    const poolsList = data.affectedPools.results.length ? data.affectedPools.results.map((i) => app.$i18n.t(i.name)) : null

    await app.$pushEvent(event, {
      jobId: data.id,
      vmsJobId: data.vmsId,
      jobTitle: data.title,
      jobPublicDescription: data.descriptionHtmlStripped,
      jobType: data.jobType,
      jobAssignmentPeriodStart: data.assignmentPeriodStart,
      jobAssignmentPeriodEnd: data.assignmentPeriodEnd,
      jobLanguage: data.jobLanguage,
      jobClass: data.jobClass,
      jobStatus: data.status !== EProjectStatus.ACTIVE ? 'CLOSED' : 'OPEN',
      positionsNumber: data.positionsNumber,
      periodDeadline: data.periodDeadline,
      legalEntityName: data.legalEntityName,
      clientName: data.originatingDisplayName,
      siteAddress: data.siteAddress,
      siteCity: data.siteCity,
      sitePostalCode: data.siteCode,
      budgetRange: `${data.rateRange.min} - ${data.rateRange.max}`,
      currency: data.rateRange.currency,
      remote: data.remote,
      hoursPerWeek: data.hoursPerWeek,
      poolNames: poolsList
    })
  })

  inject('clientI18nSeo', () => {
    const clientLocalesList = store.state.clientContext.languages.map(({ iso }) => iso)

    return {
      htmlAttrs: { lang: app.$i18n.locale },
      meta: [
        {
          hid: 'i18n-og',
          property: 'og:locale',
          content: app.$i18n.locale.replace('-', '_')
        },
        ...clientLocalesList
          .filter((iso) => iso !== app.$i18n.locale)
          .map((iso) => ({
            hid: `i18n-og-alt-${iso}`,
            property: 'og:locale:alternate',
            content: iso.replace('-', '_')
          }))
      ],
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: `/favicon.png?v=0.1&dn=${store.state.clientContext.talentDomainName}`
        }
      ]
    }
  })

  inject('headMetaTags', (opts) => {
    const imgUrl = store.state.clientContext.ogImage
      ? app.$clientCoreAsset(store.state.clientContext.ogImage, {
          format: 'jpg',
          width: 1200,
          height: 630,
          fit: 'cover'
        })
      : `https://${host}/og_default.jpg`
    const keywords = opts?.keywords
      ? [{ hid: 'keywords', name: 'keywords', content: opts.keywords }]
      : [{}]
    const description = opts?.description
      ? [{ hid: 'description', name: 'description', content: opts.description }]
      : [{}]
    const ogDescription = opts?.ogDescription
      ? [{ hid: 'og:description', property: 'og:description', content: opts.ogDescription }]
      : opts?.description
      ? [{ hid: 'og:description', property: 'og:description', content: opts.description }]
      : [{}]
    const noIndexPage = !store.state.seoIndex
      ? [{ hid: 'robots', name: 'robots', content: 'noindex' }]
      : [{}]

    return {
      title: opts.title,
      meta: [
        ...noIndexPage,
        ...keywords,
        ...description,
        ...ogDescription,
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: opts.title
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: `https://${host}${app.context.route.path}`
        },
        {
          hid: 'og:image',
          name: 'image',
          property: 'og:image',
          content: imgUrl
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '1200',
          url: imgUrl
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '630',
          url: imgUrl
        }
      ]
    }
  })
}
