import { render, staticRenderFns } from "./appBanner.vue?vue&type=template&id=68689b04&scoped=true&"
import script from "./appBanner.vue?vue&type=script&lang=js&"
export * from "./appBanner.vue?vue&type=script&lang=js&"
import style0 from "./appBanner.vue?vue&type=style&index=0&id=68689b04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68689b04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/global/modal.vue').default})
