export default (axios) => ({
  getOpenInvitations(poolId, query) {
    return axios.get(`/v1/api/pool/${poolId}/projectInvitations/open`, {
      params: {
        ...query
      }
    })
  },

  getInvitations(query) {
    return axios.get('/v1/api/projectInvitation/open', {
      params: {
        ...query
      }
    })
  }
})
