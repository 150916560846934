import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _503c2826 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _f9db6636 = () => interopDefault(import('../pages/social-callback.vue' /* webpackChunkName: "pages/social-callback" */))
const _443968c2 = () => interopDefault(import('../pages/app/account/index.vue' /* webpackChunkName: "pages/app/account/index" */))
const _964d4152 = () => interopDefault(import('../pages/app/forgot-password.vue' /* webpackChunkName: "pages/app/forgot-password" */))
const _bb81a98a = () => interopDefault(import('../pages/app/login.vue' /* webpackChunkName: "pages/app/login" */))
const _d7b5cd08 = () => interopDefault(import('../pages/app/onboarding/index.vue' /* webpackChunkName: "pages/app/onboarding/index" */))
const _f015aa86 = () => interopDefault(import('../pages/app/pool/index.vue' /* webpackChunkName: "pages/app/pool/index" */))
const _0a4e1efb = () => interopDefault(import('../pages/app/profile.vue' /* webpackChunkName: "pages/app/profile" */))
const _744a1aa4 = () => interopDefault(import('../pages/app/project/index.vue' /* webpackChunkName: "pages/app/project/index" */))
const _1bffad94 = () => interopDefault(import('../pages/app/signup.vue' /* webpackChunkName: "pages/app/signup" */))
const _06725e9a = () => interopDefault(import('../pages/app/account/activate.vue' /* webpackChunkName: "pages/app/account/activate" */))
const _1071a06c = () => interopDefault(import('../pages/app/account/confirm-change-email.vue' /* webpackChunkName: "pages/app/account/confirm-change-email" */))
const _56824588 = () => interopDefault(import('../pages/app/account/create-password.vue' /* webpackChunkName: "pages/app/account/create-password" */))
const _3154c1c4 = () => interopDefault(import('../pages/app/account/forgotPassword.vue' /* webpackChunkName: "pages/app/account/forgotPassword" */))
const _5dd664a4 = () => interopDefault(import('../pages/app/account/social-incomplete.vue' /* webpackChunkName: "pages/app/account/social-incomplete" */))
const _3c370413 = () => interopDefault(import('../pages/app/account/verifyEmail.vue' /* webpackChunkName: "pages/app/account/verifyEmail" */))
const _d8e3dc68 = () => interopDefault(import('../pages/app/mobile-activation/_number.vue' /* webpackChunkName: "pages/app/mobile-activation/_number" */))
const _db230f98 = () => interopDefault(import('../pages/app/onboarding/_slug.vue' /* webpackChunkName: "pages/app/onboarding/_slug" */))
const _694e8f36 = () => interopDefault(import('../pages/app/pool/_id.vue' /* webpackChunkName: "pages/app/pool/_id" */))
const _1b7f0699 = () => interopDefault(import('../pages/app/project/_id/index.vue' /* webpackChunkName: "pages/app/project/_id/index" */))
const _ec37612c = () => interopDefault(import('../pages/projects/ext/_id/index.vue' /* webpackChunkName: "pages/projects/ext/_id/index" */))
const _173d5b15 = () => interopDefault(import('../pages/projects/in/_city.vue' /* webpackChunkName: "pages/projects/in/_city" */))
const _602e9996 = () => interopDefault(import('../pages/app/project/_id/apply.vue' /* webpackChunkName: "pages/app/project/_id/apply" */))
const _3e4dfc06 = () => interopDefault(import('../pages/projects/ext/_id/apply.vue' /* webpackChunkName: "pages/projects/ext/_id/apply" */))
const _6ea49cf8 = () => interopDefault(import('../pages/projects/categories/_categoryName/_id/index.vue' /* webpackChunkName: "pages/projects/categories/_categoryName/_id/index" */))
const _50a414d6 = () => interopDefault(import('../pages/projects/pool/_poolName/_id/index.vue' /* webpackChunkName: "pages/projects/pool/_poolName/_id/index" */))
const _3170c24e = () => interopDefault(import('../pages/projects/pool/_poolName/_id/_city.vue' /* webpackChunkName: "pages/projects/pool/_poolName/_id/_city" */))
const _3917e081 = () => interopDefault(import('../pages/subscriptions/_uuid.vue' /* webpackChunkName: "pages/subscriptions/_uuid" */))
const _40bdc0f9 = () => interopDefault(import('../pages/projects/_title/_id.vue' /* webpackChunkName: "pages/projects/_title/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/projects",
    component: _503c2826,
    name: "projects"
  }, {
    path: "/social-callback",
    component: _f9db6636,
    name: "social-callback"
  }, {
    path: "/app/account",
    component: _443968c2,
    name: "app-account"
  }, {
    path: "/app/forgot-password",
    component: _964d4152,
    name: "app-forgot-password"
  }, {
    path: "/app/login",
    component: _bb81a98a,
    name: "app-login"
  }, {
    path: "/app/onboarding",
    component: _d7b5cd08,
    name: "app-onboarding"
  }, {
    path: "/app/pool",
    component: _f015aa86,
    name: "app-pool"
  }, {
    path: "/app/profile",
    component: _0a4e1efb,
    name: "app-profile"
  }, {
    path: "/app/project",
    component: _744a1aa4,
    name: "app-project"
  }, {
    path: "/app/signup",
    component: _1bffad94,
    name: "app-signup"
  }, {
    path: "/app/account/activate",
    component: _06725e9a,
    name: "app-account-activate"
  }, {
    path: "/app/account/confirm-change-email",
    component: _1071a06c,
    name: "app-account-confirm-change-email"
  }, {
    path: "/app/account/create-password",
    component: _56824588,
    name: "app-account-create-password"
  }, {
    path: "/app/account/forgotPassword",
    component: _3154c1c4,
    name: "app-account-forgotPassword"
  }, {
    path: "/app/account/social-incomplete",
    component: _5dd664a4,
    name: "app-account-social-incomplete"
  }, {
    path: "/app/account/verifyEmail",
    component: _3c370413,
    name: "app-account-verifyEmail"
  }, {
    path: "/app/mobile-activation/:number?",
    component: _d8e3dc68,
    name: "app-mobile-activation-number"
  }, {
    path: "/app/onboarding/:slug",
    component: _db230f98,
    name: "app-onboarding-slug"
  }, {
    path: "/app/pool/:id",
    component: _694e8f36,
    name: "app-pool-id"
  }, {
    path: "/app/project/:id",
    component: _1b7f0699,
    name: "app-project-id"
  }, {
    path: "/projects/ext/:id",
    component: _ec37612c,
    name: "projects-ext-id"
  }, {
    path: "/projects/in/:city?",
    component: _173d5b15,
    name: "projects-in-city"
  }, {
    path: "/app/project/:id/apply",
    component: _602e9996,
    name: "app-project-id-apply"
  }, {
    path: "/projects/ext/:id?/apply",
    component: _3e4dfc06,
    name: "projects-ext-id-apply"
  }, {
    path: "/projects/categories/:categoryName?/:id",
    component: _6ea49cf8,
    name: "projects-categories-categoryName-id"
  }, {
    path: "/projects/pool/:poolName?/:id",
    component: _50a414d6,
    name: "projects-pool-poolName-id"
  }, {
    path: "/projects/pool/:poolName?/:id?/:city",
    component: _3170c24e,
    name: "projects-pool-poolName-id-city"
  }, {
    path: "/subscriptions/:uuid?",
    component: _3917e081,
    name: "subscriptions-uuid"
  }, {
    path: "/projects/:title/:id?",
    component: _40bdc0f9,
    name: "projects-title-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
