
export default {
  name: 'ErrorAlert',

  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
