export default ({ $cmsApiCall, $fallbackLangs, store, $i18n }) => {
  return {
    getCategories(
      fields = [
        'id',
        'default_image',
        'translations.language_code',
        'translations.name',
        'translations.image'
      ],
      filter = {}
    ) {
      return $cmsApiCall()
        .items('d_category')
        .readByQuery({
          filter: {
            client: { client_name: { _eq: store.state.clientContext.name } },
            status: 'published',
            ...filter
          },
          fields,
          deep: {
            translations: {
              _filter: {
                _or: $fallbackLangs().map((code) => ({ language_code: { _eq: code } }))
              }
            }
          },
          limit: -1
        })
    },
    getFooterLinks(
      fields = [
        'id',
        'link_url',
        'link_label',
        'link_follow',
        'show_footer_left',
        'show_footer_right',
        'link_language',
        'link_target',
        'link_on_tux'
      ]
    ) {
      return $cmsApiCall()
        .items('d_links')
        .readByQuery({
          filter: {
            client: { client_name: { _eq: store.state.clientContext.name } },
            link_language: { _eq: $i18n.locale },
            link_on_tux: {
              _eq: true
            }
          },
          fields,
          limit: -1
        })
    }
  }
}
