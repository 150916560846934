export function extractFreelancerGtmData({ auth, store }) {
  const { id, name } = store.state.clientContext
  const { id: authUserId, created, email, firstName, isActive, lastName, subscribed } = auth.user
  const userId = `talent_${authUserId}_${id}`

  return {
    id: userId,
    userId,
    userInternalId: authUserId,
    userType: 'talent',
    businessUnit: name,
    firstName,
    lastName,
    email,
    subscribed,
    activationStatus: isActive ? 'Active' : 'Inactive',
    created
  }
}
