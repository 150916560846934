
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'AppTermsExpired',

  components: { ValidationObserver, ValidationProvider },

  data() {
    return {
      needsToAgree: false,
      deactivateDialog: false,
      missingAgreement: {},
      tc: null,
      pp: null
    }
  },

  async fetch() {
    const { data } = await this.$api.freelancer.getMissingLegalAgreements()
    this.missingAgreement = data
    this.needsToAgree = data.terms || data.privacy
  },

  computed: {
    clientTerms() {
      const getClient = this.$store.state.clientContext.name
      return this.$t(`talent.terms.expired.client.description.${getClient}`)
    },

    clientPrivacy() {
      const getClient = this.$store.state.clientContext.name
      return this.$t(`talent.privacy.expired.client.description.${getClient}`)
    }
  },

  methods: {
    async agreeToNewTerms() {
      if (await this.$refs?.validator.validate()) {
        let queryObj = {}

        if (this.missingAgreement.terms) {
          queryObj = Object.assign(queryObj, { terms: this.tc })
        }

        if (this.missingAgreement.privacy) {
          queryObj = Object.assign(queryObj, { privacy: this.pp })
        }

        try {
          await this.$api.freelancer.agreeToUpdatedPolicies(queryObj)

          this.$toast.fire(this.$t('talent.message.generic.success'))
          this.needsToAgree = false
        } catch (err) {}
      }
    },

    async deactivateConfirm() {
      try {
        await this.$api.freelancer.deactivateAccount()

        this.$router.push({ query: { deactivated: 'true' } })
        this.$auth.logout('local')
      } catch (err) {}
    }
  }
}
