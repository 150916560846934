
import { EFilter } from '@/helpers/enums'

export default {
  name: 'TextSearch',

  data() {
    return {
      inputText: ''
    }
  },

  fetch() {
    this.applyFilter(this.$route.query[EFilter.TEXT])
  },

  computed: {
    selectedSimpleQuery: {
      get() {
        return this.$route.query[EFilter.TEXT] || null
      },
      async set(val) {
        this.$emit('before-update')
        const bool = Boolean(val)

        if (bool) {
          await this.$updateQuery(this.$route.query, {
            [EFilter.TEXT]: encodeURIComponent(val)
          })
        } else {
          await this.$cleanQuery(this.$route.query, EFilter.TEXT)
        }

        this.$emit('updated')
      }
    },
    inputTextEmpty() {
      return !this.inputText?.trim()
    }
  },

  watch: {
    [`$route.query.${EFilter.TEXT}`](data) {
      if (!data) {
        this.inputText = ''
      }
    }
  },

  methods: {
    applyFilter(data) {
      if (data) {
        this.inputText = decodeURIComponent(data)
      }
    },

    fillSelected(val) {
      if (val) {
        this.selectedSimpleQuery = val
      } else {
        this.inputText = ''
      }
    },

    clearFilter() {
      this.selectedSimpleQuery = null
      this.inputText = ''
    }
  }
}
