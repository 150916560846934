import { v4 } from 'uuid'
import { EOneTimeLinkSentStatus } from '@/helpers/enums'

export default function ({ $auth, $axios, app, store }, inject) {
  let refreshTimer = null

  $auth.options.redirect = {
    login: '/app/login',
    logout: '/app/login',
    home: '/app/project',
    callback: '/app/login'
  }

  async function triggerTokenChecker() {
    const expires = $auth.$storage.getUniversal('_token_expiration.local')
    await $auth.initRefreshInterval(expires)
  }

  if (process.client) {
    $auth.$storage.watchState('loggedIn', (loggedIn) => {
      if (!loggedIn) {
        clearTimeout(refreshTimer)
        $auth.$storage.removeUniversal('activationPaused')
        store.dispatch('application/clearSavedApplication')
      }

      if (loggedIn) {
        triggerTokenChecker()
        app.$api.freelancer.setFreelancerLocale({ locale: app.$i18n.locale })
      }
    })
  }

  $axios.onRequest(() => {
    triggerTokenChecker()
  })

  $auth.initRefreshInterval = async (accessTokenExp) => {
    const currentDateTime = Date.parse(new Date())
    const refreshPeriod = accessTokenExp - currentDateTime - 60000 * 5

    if (process.client) {
      clearTimeout(refreshTimer)

      refreshTimer = setTimeout(async () => {
        await $auth.refreshTokens()
        triggerTokenChecker()
      }, refreshPeriod)
    } else if (refreshPeriod < 0) {
      await $auth.refreshTokens()
    }
  }

  inject('getBrowserUUID', async () => {
    const cookieId = '_brws_id'
    let uuid = app.$auth.$storage.getCookie(cookieId)

    if (!uuid) {
      const expires = app.$useConfig(app.$EClientConfig.USER_DEVICE_VALIDITY)
      uuid = $auth.$storage.setCookie(cookieId, v4(), { expires, httpOnly: false })
    }

    const { data: isUserDeviceTrusted } = await app.$api.freelancer.isUserDeviceTrusted(uuid)

    if (!isUserDeviceTrusted.isTrusted) {
      await app.$api.freelancer.saveUserDevice(uuid, {
        userAgent: window.navigator.userAgent,
        country: store.state.sessionCountry
      })
    }

    return uuid
  })

  inject('hasTrustedToken', () => Boolean(app.$auth.$storage.getCookie('_brws_id')))

  inject('trustedToken', () => app.$auth.$storage.getCookie('_brws_id'))

  inject('authorizeUser', async ({ token, endpoint, isOtl }) => {
    try {
      const { data } = await app.$api.login[endpoint]({
        urlToken: token,
        actorDeviceUUID: app.$trustedToken()
      })

      await app.$auth.setUserToken(data.access_token, data.refresh_token)
      return { login: 'success' }
    } catch ({ response }) {
      let errorType = null
      if (isOtl) {
        errorType =
          response?.status === 400 ? EOneTimeLinkSentStatus.EXPIRED : EOneTimeLinkSentStatus.INVALID
      }
      return { login: 'error', errorType }
    }
  })
}
