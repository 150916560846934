import { extractFreelancerGtmData } from '@/helpers/tracking'

const NUXT_GTM_TIMEOUT = 250

export default function ({ app, store }) {
  app.router.afterEach(() => {
    const auth = app.$auth

    if (auth.loggedIn) {
      setTimeout(
        () => app.$pushEvent('freelancerLoggedData', extractFreelancerGtmData({ auth, store })),
        NUXT_GTM_TIMEOUT
      )
    }
  })
}
