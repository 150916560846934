import Binaries from '@/services/binaries'
import ElasticSearch from '@/services/elasticSearch'
import Freelancer from '@/services/freelancer'
import Login from '@/services/login'
import Membership from '@/services/membership'
import Questions from '@/services/questions'
import Pool from '@/services/pool'
import Project from '@/services/project'
import ProjectApplication from '@/services/projectApplication'
import ProjectInvitation from '@/services/projectInvitation'
import PublicProjects from '@/services/publicProjects'
import Utils from '@/services/utils'
import Flow from '@/services/flow'
import Cms from '@/services/cms'

export default ({ $axios, $cmsApiCall, $fallbackLangs, store, $i18n }, inject) => {
  const factory = {
    binaries: Binaries($axios),
    elasticSearch: ElasticSearch($axios),
    freelancer: Freelancer($axios),
    login: Login($axios),
    membership: Membership($axios),
    questions: Questions($axios),
    pool: Pool($axios),
    project: Project($axios),
    projectApplication: ProjectApplication($axios),
    projectInvitation: ProjectInvitation($axios),
    publicProjects: PublicProjects($axios),
    utils: Utils($axios),
    flow: Flow($axios),
    cms: Cms({ $cmsApiCall, $fallbackLangs, store, $i18n })
  }

  inject('api', factory)
}
