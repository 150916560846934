import { render, staticRenderFns } from "./portal.vue?vue&type=template&id=4bb43dca&"
import script from "./portal.vue?vue&type=script&lang=js&"
export * from "./portal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loaders: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/loaders.vue').default,OfflineBar: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/global/offlineBar.vue').default,AppHeader: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appHeader.vue').default,AppBanner: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appBanner.vue').default,AppFooter: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appFooter.vue').default,AppTermsExpired: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appTermsExpired.vue').default,AppAnnouncement: require('/opt/atlassian/pipelines/agent/build/frontend/apps/freelancer/components/layout/appAnnouncement.vue').default})
