export const ECoverLetterInputMethod = Object.freeze({
  FILE: 1,
  INPUT: 2
})

export const EResumeInputMethod = Object.freeze({
  FILE: 1,
  GENERATED: 2
})

export const EProjectType = Object.freeze({
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
})

export const ELoginStatus = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
})

export const EFilter = Object.freeze({
  TEXT: 'any',
  POOLS: 'pools',
  LOCATION: 'location',
  HOURS: 'hoursPerWeek',
  DURATION: 'duration',
  WORK_PREFS: 'workPrefs',
  OPEN_ONLY: 'openOnly',
  PAGE: 'page',
  VMS_ID: 'vmsId',
  STATUS: 'status'
})

export const ESearchPageType = Object.freeze({
  CATEGORIES: 'CATEGORIES',
  POOLS: 'POOLS',
  CITY: 'CITY',
  POOL_IN_CITY: 'POOL_IN_CITY',
  VMS_ID: 'VMS_ID'
})

export const EBooleanSearch = Object.freeze({
  EQ: 'EQ',
  NEQ: 'NEQ',
  LIKE: 'LIKE'
})

export const EDateFormatting = Object.freeze({
  DATE_FULL: 'DD-MM-YYYY',
  DATE_MONTH: 'MM-YYYY',
  DATE_YEAR: 'YYYY-MM-DD',
  DATE_YEAR_ONLY: 'YYYY'
})

export const EDateType = Object.freeze({
  SHORT: 'SHORT',
  LONG: 'LONG',
  LONG_WITH_TIME: 'LONG_WITH_TIME',
  YEAR: 'YEAR'
})

export const EWorkModelType = Object.freeze({
  HYBRID: 'hy',
  ONSITE: 'on',
  REMOTE: 'rm'
})

export const EWorkPreference = Object.freeze({
  HYBRID: 'HYBRID',
  ONSITE: 'ONSITE',
  REMOTE: 'REMOTE'
})

export const EJobApplicationStatus = Object.freeze({
  ACTIVE: 'a',
  HIRED: 'h',
  REJECTED_BY_MSP: 'rm',
  REJECTED_BY_VMS: 'rv',
  SEND_FAILED: 'sf',
  SEND_PENDING: 'sp',
  SENT_TO_CLIENT: 's2c',
  UNCONFIRMED: 'uc',
  UNDER_CONSTRUCTION: 'co',
  WITHDRAWN: 'w',
  PLACEHOLDER: 'placeholder'
})

export const EClientConfig = Object.freeze({
  ADDRESS_VALIDATION: 'cl__address_validation',
  CURRENCIES: 'cl__currencies',
  ENABLE_COUNTRIES_OF_INTEREST: 'cl__countries_of_interest_enabled',
  ENABLE_EXTERNAL_APP_LINK: 'cl__enable_external_application_link',
  ENABLE_IP_DETECTION: 'cl__ip_detection_on_project_search',
  ENABLE_JOB_NOTIFICATION_DIGEST: 'cl__jobnotification_digest_switch_enabled',
  ENABLE_MODERN_APP_FLOW: 'cl__enable_modern_project_application',
  ENABLE_PAQ: 'cl__enable_pre_application_questions',
  ENABLE_PUBLIC_SEARCH: 'cl__disable_public_project_search',
  FREELANCER_LOGIN: 'cl__freelancer_login',
  FREELANCER_REQUIRED_VERIFICATIONS: 'cl__freelancer_required_verifications',
  PREFERRED_SORTED_COUNTRIES: 'cl__preferred_sorted_countries',
  REGISTER_NOTIFICATION_COUNTRY: 'cl__register_notification_country',
  USER_DEVICE_VALIDITY: 'cl__user_device_validity',
  EMAIL_SPECIAL_CHARACTERS: 'cl__email_special_characters',
  MOBILE_COUNTRIES_SETTINGS: 'cl__talent_mobile_countries_settings',
  FEED_JOVEO_JOBTYPE: 'cl__feed_joveo_jobtype'
})

export const EFreelancerRequiredVerfications = Object.freeze({
  PRINCIPAL: 'principal',
  ALTERNATIVE: 'alternative',
  DISABLED: 'disabled'
})

export const EPoolMembershipStatus = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  ARCHIVED_ACCEPTED: 'ARCHIVED_ACCEPTED',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  WITHDRAWN: 'WITHDRAWN'
})

export const EApplicationFeeType = Object.freeze({
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE'
})

export const ECalendarRejectionReason = Object.freeze({
  ERROR: 'ERROR',
  NO_FREE_SLOTS: 'NO_FREE_SLOTS',
  NO_SUITABLE_SLOTS: 'NO_SUITABLE_SLOTS'
})

export const EExternalUserProvider = Object.freeze({
  LINKEDIN: 'Linkedin',
  GOOGLE: 'Google',
  AUTH0: 'Auth0'
})

export const EAssessmentStatus = Object.freeze({
  CREATED: 'cr',
  STARTED: 'st',
  CANCELED: 'cn',
  EXPIRED: 'ex',
  COMPLETED: 'cm'
})

export const EInterviewStatus = Object.freeze({
  SENDING_FAILED: 'sf',
  CREATED: 'cre',
  INVITED: 'inv',
  CANCELED: 'can',
  EXPIRED: 'exp',
  STARTED: 'str',
  COMPLETED: 'com',
  CONFIRMED: 'cf',
  UNCONFIRMED: 'uc'
})

export const EAuthMethod = Object.freeze({
  LINKEDIN: 'linkedin',
  GOOGLE: 'google',
  AUTH0: 'auth0',
  EMAIL: 'email',
  ONE_TIME_LINK: 'otl',
  MOBILE: 'mobile'
})

export const EAuthMode = Object.freeze({
  SIGNUP: 'signup',
  LOGIN: 'login'
})

export const EOneTimeLinkSentStatus = Object.freeze({
  NOT_TRIGERRED: 'n_t',
  SENT: 's',
  EXPIRED: 'e',
  NOT_FOUND: 'n_f',
  INVALID: 'i'
})

export const EFlowModal = Object.freeze({
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  PATCH: 'PATCH'
})

export const EDataId = Object.freeze({
  PROFILE: 'profile-',
  ONBOARDING: 'onb-',
  SIGNUP: 'signup-with-',
  LOGIN: 'login-with-'
})

export const EProjectStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  PAUSED: 'PAUSED'
})

export const EGeoErrorStatus = Object.freeze({
  LOOKUP_ERROR: 'LOOKUP_ERROR',
  SUCCESS: 'SUCCESS',
  SERVER_ERROR: 'SERVER_ERROR',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  NOT_A_LOCATION: 'NOT_A_LOCATION'
})

export const EGeoErrorValidation = Object.freeze({
  IMPRECISE: 'IMPRECISE',
  INVALID: 'INVALID',
  REQUIRED: 'REQUIRED'
})
