const middleware = {}

middleware['deactivated'] = require('../middleware/deactivated.js')
middleware['deactivated'] = middleware['deactivated'].default || middleware['deactivated']

middleware['getBrowserUuidCookie'] = require('../middleware/getBrowserUuidCookie.js')
middleware['getBrowserUuidCookie'] = middleware['getBrowserUuidCookie'].default || middleware['getBrowserUuidCookie']

middleware['onboarding'] = require('../middleware/onboarding.js')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['profile-fullfilled'] = require('../middleware/profile-fullfilled.js')
middleware['profile-fullfilled'] = middleware['profile-fullfilled'].default || middleware['profile-fullfilled']

middleware['public-search-disabled'] = require('../middleware/public-search-disabled.js')
middleware['public-search-disabled'] = middleware['public-search-disabled'].default || middleware['public-search-disabled']

middleware['search-redirect'] = require('../middleware/search-redirect.js')
middleware['search-redirect'] = middleware['search-redirect'].default || middleware['search-redirect']

middleware['tracker'] = require('../middleware/tracker.js')
middleware['tracker'] = middleware['tracker'].default || middleware['tracker']

middleware['trusted-token'] = require('../middleware/trusted-token.js')
middleware['trusted-token'] = middleware['trusted-token'].default || middleware['trusted-token']

export default middleware
