export default (axios) => ({
  getPool(poolId) {
    return axios.get(`/v1/api/pool/${poolId}`)
  },

  deletePoolApplication(deleteId) {
    return axios.delete(`/v1/api/pool/${deleteId}/membership/cancel`)
  },

  toggleJobNotificationsByPool(poolId, query) {
    return axios.post(
      `/v1/api/pool/${poolId}/toggleJobNotification`,
      {},
      {
        params: {
          ...query
        }
      }
    )
  },

  toggleJobNotificationsByUuidAndPool(uuid, poolId, query) {
    return axios.post(
      `/v1/api/pool/${poolId}/toggleJobNotification/${uuid}`,
      {},
      {
        params: {
          ...query
        }
      }
    )
  }
})
