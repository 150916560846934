export default (axios) => ({
  getProjects(payload) {
    return axios.post('/v1/api/search/project/query', payload)
  },

  getPublicProjects(payload) {
    return axios.post('/v1/api/publicsearch/project/query', payload)
  },

  getPools(payload) {
    return axios.post('/v1/api/search/pool/query', payload)
  }
})
