import { compact } from 'lodash-es'

const poolRequestData = {
  sort: [{ name: 'id', dir: 'desc' }],
  offset: 0,
  query: '',
  size: 500,
  filters: {}
}

export const state = () => ({
  getLocations: [],
  poolRequestData: { ...poolRequestData },
  categorizedPoolsList: [],
  orphanPoolsList: []
})

export const actions = {
  async getLocations({ commit }, payload) {
    try {
      const { data: locations } = await this.$api.project.getJobsLocations(payload)

      const treeList = []
      const countryList = [...new Set(locations.map(({ country }) => country))]

      for (let i = 0; i < countryList.length; i++) {
        const iso = countryList[i]

        treeList.push(
          {
            id: `country_${i}`,
            name: this.$parseCountryName(iso),
            value: iso,
            country: iso,
            isCountry: true
          },
          ...compact(
            locations.map((e, idx) => {
              if (e.country === iso) {
                return {
                  id: `city_${i}_${idx}`,
                  name: e.city,
                  value: e.city,
                  country: iso
                }
              } else {
                return null
              }
            })
          )
        )
      }

      commit('GET_LOCATIONS', treeList)
    } catch ({ response }) {
      console.log(`API REQUEST FAILED FOR SITE LOCATIONS`)
    }
  },

  async getPools({ state, commit }, payload) {
    if (payload && payload.query) {
      await commit('SEARCH_POOL', { query: payload.data })
    } else {
      await commit('SEARCH_POOL', { ...poolRequestData })
    }

    try {
      const makePoolsList = (pools) =>
        pools
          .map((pool) => ({
            ...pool,
            name: this.$i18n.t(pool.name),
            description: this.$i18n.t(pool.descriptionKey)
          }))
          .sort((a, b) => a.name.localeCompare(b.name))

      const { data } = await this.$api.elasticSearch.getPools(state.poolRequestData)
      const pools = [...(data?.results ?? [])]
      const hasAnyCategoryId = pools.some((pool) => typeof pool.categoryId === 'number')

      const categorizedPools = []
      const orphanPools = []

      if (hasAnyCategoryId) {
        try {
          const { data: categoriesData } = await this.$api.cms.getCategories([
            'id',
            'translations.language_code',
            'translations.name'
          ])

          const categoryIds = categoriesData.map(({ id }) => id)

          for (const pool of pools) {
            if (categoryIds.includes(pool.categoryId)) {
              categorizedPools.push(pool)
            } else {
              orphanPools.push(pool)
            }
          }

          const categories = categoriesData
            .reduce((acc, cat) => {
              const rawChildren = categorizedPools.filter(({ categoryId }) => categoryId === cat.id)

              if (rawChildren.length) {
                acc.push({
                  id: cat.id,
                  name: this.app.$cmsTranslation(cat, 'name'),
                  children: makePoolsList(rawChildren)
                })
              }

              return acc
            }, [])
            .sort((a, b) => a.name.localeCompare(b.name))

          commit('GET_CATEGORIZED_POOLS', categories)
        } catch (error) {
          console.error('[CMS FAILED]', error)
          orphanPools.push(...pools)
        }
      } else {
        orphanPools.push(...pools)
      }

      if (orphanPools.length) {
        const orphans = makePoolsList(orphanPools)
        commit('GET_ORPHAN_POOLS', orphans)
      }
    } catch (error) {
      console.error('[POOLS FAILED]', error)
    }
  }
}

export const mutations = {
  GET_LOCATIONS: (state, payload) => {
    state.getLocations = payload
  },

  GET_CATEGORIZED_POOLS: (state, payload) => {
    state.categorizedPoolsList = payload
  },

  GET_ORPHAN_POOLS: (state, payload) => {
    state.orphanPoolsList = payload
  },

  SEARCH_POOL: (state, payload) => {
    state.poolRequestData = { ...state.poolRequestData, ...payload }
  }
}

export const getters = {
  getLocations: (state) => {
    return state.getLocations
  },

  categorizedPoolsList: (state) => {
    return state.categorizedPoolsList
  },

  orphanPoolsList: (state) => {
    return state.orphanPoolsList
  }
}
