export default ({ store }, inject) => {
  inject('hiddenMap', (property) => {
    const hiddenFields = store.state.hiddenFields

    switch (property) {
      case 'homeAddress.addressLine1':
        return !hiddenFields.includes('homeAddress.addressLine1')
      case 'homeAddress.addressLine2':
        return !hiddenFields.includes('homeAddress.addressLine2')
      case 'homeAddress.street':
        return !hiddenFields.includes('homeAddress.street')
      case 'homeAddress.city':
        return !hiddenFields.includes('homeAddress.city')
      case 'homeAddress.zip':
        return !hiddenFields.includes('homeAddress.zip')
      case 'homeAddress.state':
        return !hiddenFields.includes('homeAddress.state')
      case 'homeAddress.country':
        return !hiddenFields.includes('homeAddress.country')
      case 'mobileNumber':
        return !hiddenFields.includes('mobileNumber')
      case 'profileImage':
        return !hiddenFields.includes('profileImage')
      case 'firstName':
        return !hiddenFields.includes('firstName')
      case 'lastName':
        return !hiddenFields.includes('lastName')
      case 'professionalTitle':
        return !hiddenFields.includes('professionalTitle')
      case 'availability':
        return !hiddenFields.includes('availability')
      case 'description':
        return !hiddenFields.includes('description')
      case 'linkedinHandle':
        return !hiddenFields.includes('linkedinHandle')
      case 'hourRate':
        return !hiddenFields.includes('hourRate')
    }
  })
}
