import { pick } from 'lodash-es'
import { EResumeInputMethod } from '@/helpers/enums'

const DEFAULT_RATE_VALUE = {
  value: null,
  currency: 'EUR',
  period: ''
}

const cvObj = {
  contentType: 'application/pdf',
  id: null,
  name: '',
  uuid: ''
}

const cvCoverObj = {
  contentType: 'application/pdf',
  name: '',
  uuid: ''
}

const postAnswersArr = (arr) => {
  const reduced = arr.reduce((acc, e) => {
    const id = e.id
    const answersObj = arr.map((e) => {
      if (e.id === id) {
        return JSON.stringify({
          type: e.type,
          value: e.value,
          groupId: e.groupId
        })
      }

      return false
    })
    const answersArr = answersObj.filter((e) => e)

    if (acc[id] !== (acc[id] = [])) {
      acc[id].push({
        questionId: e.id,
        answers: answersArr
      })
    }

    return acc
  }, {})

  return Object.values(reduced)
}

export const state = () => ({
  applicationId: null,
  canEdit: false,
  blockApplication: false,
  finalRate: '',
  parentAnswersList: [],
  childAnswersList: [],
  displayRate: { ...DEFAULT_RATE_VALUE },
  talentRate: { ...DEFAULT_RATE_VALUE },
  cvsList: [],
  selectedCvObj: { ...cvObj },
  motivationComment: '',
  cvCover: { ...cvCoverObj },
  calendarSlots: {},
  interviewSlot: {},
  alternativeInterviewSlot: false,
  applicationSteps: [],
  isRestored: false,
  selectedResumeMethod: undefined,
  selectedCoverLetterMethod: EResumeInputMethod.FILE,
  openApplications: 0,
  openInvitations: 0
})

export const actions = {
  editApplication({ state, commit }, data) {
    const getSelectedCv = state.cvsList.find((item) => item.uuid === data.cvUuid)

    commit(
      'SET_RESUME_METHOD',
      data.cvUuid ? EResumeInputMethod.FILE : EResumeInputMethod.GENERATED
    )
    commit('SELECTED_CV_OBJ', getSelectedCv)
    commit('CV_COVER', data.cvCover)
    commit('DISPLAY_RATE', pick(data.displayRate, ['currency', 'period', 'value']))
    commit('TALENT_RATE', pick(data.talentRate, ['currency', 'period', 'value']))
    commit('MOTIVATION_COMMENT', data.motivationComment)

    if (data.interviewSlot && Object.keys(data.interviewSlot).length > 0) {
      commit('INTERVIEW_SLOT', data.interviewSlot)
      commit('ALTERNATIVE_INTERVIEW_SLOT', data.alternativeInterviewSlot)
    } else if (data.liveInterview && Object.keys(data.liveInterview).length > 0) {
      commit('INTERVIEW_SLOT', data.liveInterview)
      commit('ALTERNATIVE_INTERVIEW_SLOT', data.alternativeInterviewSlot)
    } else {
      commit('ALTERNATIVE_INTERVIEW_SLOT', true)
    }
  },

  clearAppliction({ commit }) {
    commit('SET_RESUME_METHOD', undefined)
    commit('SELECTED_CV_OBJ', null)
    commit('CV_COVER', null)
    commit('DISPLAY_RATE', { ...DEFAULT_RATE_VALUE })
    commit('TALENT_RATE', { ...DEFAULT_RATE_VALUE })
    commit('MOTIVATION_COMMENT', '')
    commit('PARENT_ANSWERS_LIST', [])
    commit('CHILD_ANSWERS_LIST', [])
    commit('INTERVIEW_SLOT', '')
    commit('ALTERNATIVE_INTERVIEW_SLOT', false)
    commit('CAN_EDIT', {
      canEdit: false,
      applicationId: null
    })
  },

  removeScope({ state, commit }, payload) {
    const id = payload.id
    const scopeIdx = state.childAnswersList.findIndex((e) => e.scopeId === id)
    const newList = this.$manageArray(state.childAnswersList, {
      action: 'DELETE',
      index: scopeIdx
    })
    const sorted = this.$sortArray(newList, 'id')

    commit('CHILD_ANSWERS_LIST', sorted)
  },

  fillChildAnswers({ state, commit }, payload) {
    const answersList = [...state.childAnswersList]
    const payloadParent = payload.data.find((e) => e.scopeId)
    const isParent = payload.data.some((e) => e.scopeId)

    const gatherData = (oldList, loaded) => {
      let modifyList = []
      const answersFlatArr = this.$flatten(oldList)

      switch (loaded.action) {
        case 'REMOVE':
          modifyList = answersFlatArr.filter((e) => e.groupId !== loaded.data[0].groupId)
          break
        case 'MODIFY':
          modifyList = this.$mergeArray(answersFlatArr, loaded.data, 'groupId')
          break
        default:
          modifyList = []
          break
      }

      const sorted = this.$sortArray(modifyList, 'id')
      return this.$groupingAnswers(sorted)
    }

    if (isParent) {
      const matchdAnswers = answersList.find((e) => e.scopeId === payloadParent.scopeId)

      if (!matchdAnswers) {
        answersList.push({
          scopeId: payloadParent.scopeId,
          data: gatherData(matchdAnswers || [], payload)
        })

        commit('CHILD_ANSWERS_LIST', answersList)
      } else {
        const updatedAnswers = [
          {
            scopeId: payloadParent.scopeId,
            data: gatherData(matchdAnswers.data, payload)
          }
        ]
        const isUpdated = this.$mergeArray(answersList, updatedAnswers, 'scopeId')

        commit('CHILD_ANSWERS_LIST', isUpdated)
      }
    }
  },

  async fillParentAnswers({ state, commit }, payload) {
    const storeAnswers = await this.$mergeArray(state.parentAnswersList, payload, 'id')
    const sorted = this.$sortArray(storeAnswers, 'id')

    commit('PARENT_ANSWERS_LIST', sorted)
  },

  submitData({ state }, jobId) {
    let coverData = null

    const flatChildAnswers = this.$flatten(state.childAnswersList.map((e) => e.data))
    const parentAnswers = state.parentAnswersList
    const preparePostAnswers = [...flatChildAnswers, ...parentAnswers]
    const getAllAnswers = postAnswersArr(preparePostAnswers)
    const postAnswers = this.$flatten(getAllAnswers)

    if (state.cvCover?.uuid) {
      coverData = {
        uuid: state.cvCover.uuid,
        name: state.cvCover.name,
        type: 'application/pdf'
      }
    } else {
      coverData = null
    }

    let interviewSlot = null

    if (state.interviewSlot?.initialStart) {
      interviewSlot = {
        start: state.interviewSlot.initialStart,
        end: state.interviewSlot.initialEnd
      }
    }

    return {
      job: Number(jobId),
      hourlyRate: {
        currency: state.talentRate.currency,
        value: parseFloat(state.talentRate.value)
      },
      cvUuid: state.selectedCvObj.uuid,
      motivationComment: state.motivationComment,
      appAnswers: postAnswers,
      cvCover: coverData,
      liveInterview: state.alternativeInterviewSlot ? null : interviewSlot
    }
  },

  saveApplication({ state }, payload) {
    if (typeof localStorage === 'undefined') return

    const saveObj = {
      stepName: payload.stepName,
      lastUpdated: Date.parse(new Date()),
      parentAnswersList: state.parentAnswersList,
      childAnswersList: state.childAnswersList,
      displayRate: state.displayRate,
      talentRate: state.talentRate,
      selectedCvObj: state.selectedCvObj,
      motivationComment: state.motivationComment,
      cvCover: state.cvCover,
      interviewSlot: state.interviewSlot,
      alternativeInterviewSlot: state.alternativeInterviewSlot,
      selectedResumeMethod: state.selectedResumeMethod,
      selectedCoverLetterMethod: state.selectedCoverLetterMethod
    }

    localStorage.setItem(`application-${payload.projectId}`, JSON.stringify(saveObj))
  },

  async restoreApplication({ commit }, payload) {
    if (typeof localStorage === 'undefined') return

    const data = await JSON.parse(localStorage.getItem(`application-${payload.projectId}`))
    if (!data) return

    const currentTime = Date.parse(new Date())
    const lastUpdated = data.lastUpdated + 24 * 60 * 60 * 1000 * 3 // 3 days
    const isExpired = currentTime > lastUpdated
    if (isExpired) {
      return localStorage.removeItem(`application-${payload.projectId}`)
    }

    // Required to fetch cv resources before applicationCvs or applicationSummary mounted
    if (data.selectedResumeMethod === EResumeInputMethod.GENERATED) {
      const { data: myCvs } = await this.$api.freelancer.getAllCVResources()
      const result = myCvs.results
      const filtered = result.filter((item) => {
        return item.contentType === 'application/pdf'
      })
      const sortedById = this.$sortArray(filtered, 'id', 'desc')
      commit('GET_CVS_LIST', sortedById)

      // Verify CV to avoid error on loading deleted cv
      const isCvExist = result.filter((cv) => cv.uuid === data.selectedCvObj.uuid).length > 0
      if (isCvExist) {
        commit('SELECTED_CV_OBJ', data.selectedCvObj)
      }
    }

    commit('PARENT_ANSWERS_LIST', data.parentAnswersList)
    commit('CHILD_ANSWERS_LIST', data.childAnswersList)
    commit('CV_COVER', data.cvCover)
    commit('DISPLAY_RATE', data.displayRate)
    commit('TALENT_RATE', data.talentRate)
    commit('MOTIVATION_COMMENT', data.motivationComment)
    commit('SET_RESUME_METHOD', data.selectedResumeMethod)
    commit('SET_COVER_LETTER_METHOD', data.selectedCoverLetterMethod)

    commit('INTERVIEW_SLOT', data.interviewSlot)
    commit('ALTERNATIVE_INTERVIEW_SLOT', data.alternativeInterviewSlot)

    commit('IS_RESTORED', true)
    return data
  },

  clearSavedApplication({ commit }, projectId) {
    commit('IS_RESTORED', false)

    if (typeof localStorage === 'undefined') return

    if (projectId) {
      return localStorage.removeItem(`application-${projectId}`)
    }

    // remove all saved application in localStorage
    for (const key in localStorage) {
      if (key.includes('application-')) {
        const storageKey = key.match(/application-\w+/)[0] || null
        localStorage.removeItem(storageKey)
      }
    }
  },

  updateRates({ commit }, { displayRate, talentRate }) {
    commit('DISPLAY_RATE', displayRate)
    commit('TALENT_RATE', talentRate)
  },

  clearRates({ commit }) {
    commit('DISPLAY_RATE', { ...DEFAULT_RATE_VALUE })
    commit('TALENT_RATE', { ...DEFAULT_RATE_VALUE })
  }
}

export const mutations = {
  PARENT_ANSWERS_LIST: (state, payload) => {
    state.parentAnswersList = payload
  },

  CHILD_ANSWERS_LIST: (state, payload) => {
    state.childAnswersList = payload
  },

  CAN_EDIT: (state, payload) => {
    state.canEdit = payload.canEdit
    state.applicationId = payload.applicationId
  },

  DISPLAY_RATE: (state, payload) => {
    state.displayRate = { ...state.displayRate, ...payload }
  },

  TALENT_RATE: (state, payload) => {
    state.talentRate = { ...state.talentRate, ...payload }
  },

  BLOCK_APP: (state, payload) => {
    state.blockApplication = payload
  },

  GET_CVS_LIST: (state, payload) => {
    state.cvsList = payload
  },

  SELECTED_CV_OBJ: (state, payload) => {
    state.selectedCvObj = payload || { ...cvObj }
  },

  MOTIVATION_COMMENT: (state, payload) => {
    state.motivationComment = payload
  },

  FINAL_RATE: (state, payload) => {
    state.finalRate = payload
  },

  CV_COVER: (state, payload) => {
    state.cvCover = payload || { ...cvCoverObj }
  },

  CALENDAR_SLOTS: (state, payload) => {
    state.calendarSlots = payload
  },

  INTERVIEW_SLOT: (state, payload) => {
    state.interviewSlot = payload
  },

  ALTERNATIVE_INTERVIEW_SLOT: (state, payload) => {
    state.alternativeInterviewSlot = payload
  },

  APPLICATION_STEPS: (state, payload) => {
    state.applicationSteps = payload
  },

  IS_RESTORED: (state, payload) => {
    state.isRestored = payload
  },

  SET_RESUME_METHOD: (state, payload) => {
    state.selectedResumeMethod = payload
  },

  SET_COVER_LETTER_METHOD: (state, payload) => {
    state.selectedCoverLetterMethod = payload
  },

  RESET_COVER_LETTER_FILE: (state) => {
    state.cvCover = { ...cvCoverObj }
  },

  RESET_RESUME_FILE: (state) => {
    state.selectedCvObj = { ...cvObj }
  },

  SET_OPEN_APPLICATIONS: (state, payload) => {
    state.openApplications = payload
  },

  SET_OPEN_INVITATIONS: (state, payload) => {
    state.openInvitations = payload
  }
}

export const getters = {
  parentAnswersList: (state) => {
    return state.parentAnswersList
  },

  childAnswersList: (state) => {
    return state.childAnswersList
  },

  canEdit: (state) => {
    return state.canEdit
  },

  displayRate: (state) => {
    return state.displayRate
  },

  talentRate: (state) => {
    return state.talentRate
  },

  blockApplication: (state) => {
    return state.blockApplication
  },

  cvsList: (state) => {
    return state.cvsList
  },

  selectedCvObj: (state) => {
    return state.selectedCvObj
  },

  motivationComment: (state) => {
    return state.motivationComment
  },

  finalRate: (state) => {
    return state.finalRate
  },

  cvCover: (state) => {
    return state.cvCover
  },

  calendarSlots: (state) => {
    return state.calendarSlots
  },

  interviewSlot: (state) => {
    return state.interviewSlot
  },

  alternativeInterviewSlot: (state) => {
    return state.alternativeInterviewSlot
  },

  applicationSteps: (state) => {
    return state.applicationSteps
  },

  isRestored: (state) => {
    return state.isRestored
  },

  selectedResumeMethod: ({ selectedResumeMethod }) => selectedResumeMethod,

  selectedCoverLetterMethod: ({ selectedCoverLetterMethod }) => selectedCoverLetterMethod,
  openApplications: (state) => state.openApplications,
  openInvitations: (state) => state.openInvitations
}
