export default (axios) => ({
  getOnboarding() {
    return axios.get('/v1/api/flexOnboarding')
  },

  updateOnboarding(body) {
    return axios.put('/v1/api/flexOnboarding', body)
  },

  submitLong(body) {
    return axios.post('/v1/api/flexOnboarding/long/store', body)
  },

  submitShort(body) {
    return axios.post('/v1/api/flexOnboarding/short/store', body)
  },

  getCategories() {
    return axios.get('/v1/api/client/categories')
  },

  getPoolsByCategories(query) {
    return axios.get('/v1/api/pool/categories', query)
  },

  parseCv(query) {
    return axios.get('/v1/api/freelancer/cv/parse', {
      params: {
        ...query
      },
      validateStatus(status) {
        return status === 400 || (status >= 200 && status < 300)
      }
    })
  },

  uploadedDocument(payload) {
    const formData = new FormData()
    formData.append('cv', payload)

    return axios.post('/v1/api/freelancer/cv', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        isOverWriteCV: true
      }
    })
  },

  geoValidate(body) {
    return axios.post('/v1/api/geo/resolve', body, { blockInterceptions: true })
  }
})
