export default function ({ app, redirect, $auth }) {
  if ($auth.loggedIn) {
    const verificationsKey = app.$EClientConfig.FREELANCER_REQUIRED_VERIFICATIONS
    const mobileUser = app.$useConfig(verificationsKey).mobileNumber

    if (!mobileUser && (!$auth.user.hasPasswordOrSocial || !$auth.user.residenceCountry)) {
      return redirect(app.$link.accountCreatePassword())
    } else if (!$auth.user.hasCompletedOnboarding) {
      return redirect(app.$link.onboarding())
    }
  }
}
