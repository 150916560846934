import {
  mdiLinkBoxVariant,
  mdiChevronDown,
  mdiChevronUp,
  mdiArrowLeftThin,
  mdiCamera,
  mdiWifiOff,
  mdiFire,
  mdiCashRegister,
  mdiPercent,
  mdiCashMultiple,
  mdiBriefcaseClock,
  mdiCloudUpload,
  mdiContentCopy,
  mdiLabel,
  mdiLogout,
  mdiCog,
  mdiAccountGroup,
  mdiAccountCheck,
  mdiAccountClock,
  mdiDomain,
  mdiWifi,
  mdiAccount,
  mdiHomeCity,
  mdiPhone,
  mdiAlertCircle,
  mdiFileDocumentOutline,
  mdiMedal,
  mdiSchool,
  mdiTrashCanOutline,
  mdiBriefcase,
  mdiBriefcaseOutline,
  mdiPlusCircleOutline,
  mdiPlusCircle,
  mdiGoogle,
  mdiLinkedin,
  mdiEye,
  mdiEyeOff,
  mdiEarth,
  mdiLockOutline,
  mdiEmail,
  mdiEmailOutline,
  mdiWeb,
  mdiHelpCircle,
  mdiFilter,
  mdiCalculator,
  mdiClock,
  mdiCalendar,
  mdiCalendarRange,
  mdiCalendarRemove,
  mdiMapMarker,
  mdiLock,
  mdiPencilOutline,
  mdiLogin,
  mdiArrowRight,
  mdiArrowLeft,
  mdiArrowTopRight,
  mdiMagnify,
  mdiCheck,
  mdiCloseCircle,
  mdiClose,
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiInformation,
  mdiInformationOutline,
  mdiExclamation,
  mdiAlert,
  mdiChevronRight,
  mdiChevronLeft,
  mdiCheckboxMarked,
  mdiCheckboxBlankOutline,
  mdiMinusBox,
  mdiCircle,
  mdiArrowUp,
  mdiMenu,
  mdiMenuDown,
  mdiRadioboxMarked,
  mdiRadioboxBlank,
  mdiPencil,
  mdiStarOutline,
  mdiStar,
  mdiStarHalf,
  mdiCached,
  mdiPageFirst,
  mdiPageLast,
  mdiUnfoldMoreHorizontal,
  mdiPaperclip,
  mdiPlus,
  mdiMinus,
  mdiTwitter,
  mdiFacebook,
  mdiInstagram,
  mdiYoutube,
  mdiDotsVertical,
  mdiUpload,
  mdiArrowDown,
  mdiTrayArrowDown,
  mdiProgressWrench,
  mdiCancel,
  mdiHammerWrench,
  mdiAccountPlus,
  mdiCardAccountMailOutline,
  mdiBellOutline,
  mdiSecurity,
  mdiCellphoneLink,
  mdiReceiptTextOutline,
  mdiHelpBox,
  mdiLink,
  mdiNewspaper,
  mdiCellphoneText
} from '@mdi/js'
export default {
  mdiLinkBoxVariant,
  mdiChevronDown,
  mdiChevronUp,
  mdiArrowLeftThin,
  mdiCamera,
  mdiWifiOff,
  mdiFire,
  mdiCashRegister,
  mdiPercent,
  mdiCashMultiple,
  mdiCheckCircleOutline,
  mdiCheckCircle,
  mdiBriefcaseClock,
  mdiCloudUpload,
  mdiContentCopy,
  mdiLabel,
  mdiLogout,
  mdiCog,
  mdiAccountGroup,
  mdiAccountCheck,
  mdiAccountClock,
  mdiDomain,
  mdiWifi,
  mdiAccount,
  mdiHomeCity,
  mdiPhone,
  mdiAlert,
  mdiAlertCircle,
  mdiInformation,
  mdiInformationOutline,
  mdiFileDocumentOutline,
  mdiMedal,
  mdiSchool,
  mdiTrashCanOutline,
  mdiBriefcase,
  mdiBriefcaseOutline,
  mdiPlusCircleOutline,
  mdiPlusCircle,
  mdiGoogle,
  mdiLinkedin,
  mdiEye,
  mdiEyeOff,
  mdiEarth,
  mdiLockOutline,
  mdiEmail,
  mdiEmailOutline,
  mdiWeb,
  mdiHelpCircle,
  mdiMenu,
  mdiFilter,
  mdiChevronRight,
  mdiCalculator,
  mdiClock,
  mdiCalendar,
  mdiCalendarRange,
  mdiCalendarRemove,
  mdiMapMarker,
  mdiLock,
  mdiPencilOutline,
  mdiLogin,
  mdiArrowRight,
  mdiArrowTopRight,
  mdiArrowLeft,
  mdiMagnify,
  mdiMenuDown,
  mdiCloseCircle,
  mdiClose,
  mdiTwitter,
  mdiFacebook,
  mdiInstagram,
  mdiYoutube,
  mdiPencil,
  mdiDotsVertical,
  mdiUpload,
  mdiArrowDown,
  mdiArrowUp,
  mdiTrayArrowDown,
  mdiProgressWrench,
  mdiCancel,
  mdiHammerWrench,
  mdiAccountPlus,
  mdiCardAccountMailOutline,
  mdiBellOutline,
  mdiSecurity,
  mdiCellphoneLink,
  mdiReceiptTextOutline,
  mdiHelpBox,
  mdiLink,
  mdiNewspaper,
  mdiCellphoneText,

  //
  // bellow are default icons for vuetify components
  complete: mdiCheck,
  cancel: mdiCloseCircle,
  close: mdiClose,
  delete: mdiCloseCircle,
  clear: mdiClose,
  success: mdiCheckCircle,
  info: mdiInformation,
  infoOutline: mdiInformationOutline,
  warning: mdiExclamation,
  error: mdiAlert,
  prev: mdiChevronLeft,
  next: mdiChevronRight,
  checkboxOn: mdiCheckboxMarked,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxIndeterminate: mdiMinusBox,
  delimiter: mdiCircle,
  sort: mdiArrowUp,
  expand: mdiChevronDown,
  menu: mdiMenu,
  subgroup: mdiMenuDown,
  dropdown: mdiMenuDown,
  radioOn: mdiRadioboxMarked,
  radioOff: mdiRadioboxBlank,
  edit: mdiPencilOutline,
  ratingEmpty: mdiStarOutline,
  ratingFull: mdiStar,
  ratingHalf: mdiStarHalf,
  loading: mdiCached,
  first: mdiPageFirst,
  last: mdiPageLast,
  unfold: mdiUnfoldMoreHorizontal,
  file: mdiPaperclip,
  plus: mdiPlus,
  minus: mdiMinus,
  // prettier-ignore
  bell:'M4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22Z',
  // prettier-ignore
  suitcase: 'M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V8C2 7.45 2.19583 6.97917 2.5875 6.5875C2.97917 6.19583 3.45 6 4 6H8V4C8 3.45 8.19583 2.97917 8.5875 2.5875C8.97917 2.19583 9.45 2 10 2H14C14.55 2 15.0208 2.19583 15.4125 2.5875C15.8042 2.97917 16 3.45 16 4V6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM10 6H14V4H10V6Z',
  // prettier-ignore
  broom: 'M8.24997 8.25H9.74997V3C9.74997 2.7875 9.6781 2.60938 9.53435 2.46563C9.3906 2.32188 9.21247 2.25 8.99997 2.25C8.78747 2.25 8.60935 2.32188 8.4656 2.46563C8.32185 2.60938 8.24997 2.7875 8.24997 3V8.25ZM3.74997 11.25H14.25V9.75H3.74997V11.25ZM2.66247 15.75H4.49997V14.25C4.49997 14.0375 4.57185 13.8594 4.7156 13.7156C4.85935 13.5719 5.03747 13.5 5.24997 13.5C5.46247 13.5 5.6406 13.5719 5.78435 13.7156C5.9281 13.8594 5.99997 14.0375 5.99997 14.25V15.75H8.24997V14.25C8.24997 14.0375 8.32185 13.8594 8.4656 13.7156C8.60935 13.5719 8.78747 13.5 8.99997 13.5C9.21247 13.5 9.3906 13.5719 9.53435 13.7156C9.6781 13.8594 9.74997 14.0375 9.74997 14.25V15.75H12V14.25C12 14.0375 12.0718 13.8594 12.2156 13.7156C12.3593 13.5719 12.5375 13.5 12.75 13.5C12.9625 13.5 13.1406 13.5719 13.2843 13.7156C13.4281 13.8594 13.5 14.0375 13.5 14.25V15.75H15.3375L14.5875 12.75H3.41247L2.66247 15.75ZM15.3375 17.25H2.66247C2.17497 17.25 1.78122 17.0563 1.48122 16.6688C1.18122 16.2813 1.09372 15.85 1.21872 15.375L2.24997 11.25V9.75C2.24997 9.3375 2.39685 8.98438 2.6906 8.69063C2.98435 8.39688 3.33747 8.25 3.74997 8.25H6.74997V3C6.74997 2.375 6.96872 1.84375 7.40622 1.40625C7.84372 0.96875 8.37497 0.75 8.99997 0.75C9.62497 0.75 10.1562 0.96875 10.5937 1.40625C11.0312 1.84375 11.25 2.375 11.25 3V8.25H14.25C14.6625 8.25 15.0156 8.39688 15.3093 8.69063C15.6031 8.98438 15.75 9.3375 15.75 9.75V11.25L16.7812 15.375C16.9437 15.85 16.8718 16.2813 16.5656 16.6688C16.2593 17.0563 15.85 17.25 15.3375 17.25Z',
  // prettier-ignore
  attachment: 'M4.09956 13.1504c.70302.7031 1.65621 1.0985 2.65044 1.0996.99423-.0011 1.94742-.3965 2.65044-1.0996.70306-.703 1.09846-1.6562 1.09956-2.6504v-6.75c0-.59674-.2371-1.16903-.65901-1.59099-.42196-.42196-.99425-.65901-1.59099-.65901s-1.16903.23705-1.59099.65901-.65901.99425-.65901 1.59099v7.5h1.5v-7.5c0-.19891.07902-.38968.21967-.53033s.33142-.21967.53033-.21967.38968.07902.53033.21967.21967.33142.21967.53033v6.75c0 .5967-.23705 1.169-.65901 1.591-.42196.4219-.99425.659-1.59099.659s-1.16903-.2371-1.59099-.659c-.42196-.422-.65901-.9943-.65901-1.591v-8.25h-1.5v8.25c.00109.9942.39653 1.9474 1.09956 2.6504zm15.40044 9.3496h-11.25c-.39768-.0005-.77895-.1586-1.06016-.4398-.2812-.2813-.43938-.6625-.43984-1.0602v-4.5h1.5v4.5h11.25v-16.5h-6.75v-1.5h6.75c.3977.00046.7789.15864 1.0602.43984.2812.28121.4393.66248.4398 1.06016v16.5c-.0005.3977-.1586.7789-.4398 1.0602-.2813.2812-.6625.4393-1.0602.4398zm-1.5-15h-5.25v1.5h5.25zm-6 3.75h6v1.5h-6zm6 3.75h-6.75v1.5h6.75z',
  // prettier-ignore
  share: 'M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7625 15.025 18.6375C15.0417 18.5125 15.0667 18.4 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.6458 7.1 14.7875C6.75 14.9292 6.38333 15 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.07083 7.1 9.2125C7.45 9.35417 7.76667 9.55 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.4875 15.025 5.3625C15.0083 5.2375 15 5.11667 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92917 16.9 7.7875C16.55 7.64583 16.2333 7.45 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5125 8.975 11.6375C8.99167 11.7625 9 11.8833 9 12C9 12.1167 8.99167 12.2375 8.975 12.3625C8.95833 12.4875 8.93333 12.6 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3542 16.9 16.2125C17.25 16.0708 17.6167 16 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z',
  // prettier-ignore
  checkCircle: 'M 10.6 16.6 L 17.65 9.55 L 16.25 8.15 L 10.6 13.8 L 7.75 10.95 L 6.35 12.35 L 10.6 16.6 Z M 12 22 C 10.616 22 9.316 21.738 8.1 21.212 C 6.884 20.688 5.825 19.975 4.925 19.075 C 4.025 18.175 3.312 17.116 2.788 15.9 C 2.262 14.684 2 13.384 2 12 C 2 10.616 2.262 9.316 2.788 8.1 C 3.312 6.884 4.025 5.825 4.925 4.925 C 5.825 4.025 6.884 3.312 8.1 2.788 C 9.316 2.262 10.616 2 12 2 C 13.384 2 14.684 2.262 15.9 2.788 C 17.116 3.312 18.175 4.025 19.075 4.925 C 19.975 5.825 20.688 6.884 21.212 8.1 C 21.738 9.316 22 10.616 22 12 C 22 13.384 21.738 14.684 21.212 15.9 C 20.688 17.116 19.975 18.175 19.075 19.075 C 18.175 19.975 17.116 20.688 15.9 21.212 C 14.684 21.738 13.384 22 12 22 Z M 12 20 C 14.234 20 16.125 19.225 17.675 17.675 C 19.225 16.125 20 14.234 20 12 C 20 9.766 19.225 7.875 17.675 6.325 C 16.125 4.775 14.234 4 12 4 C 9.766 4 7.875 4.775 6.325 6.325 C 4.775 7.875 4 9.766 4 12 C 4 14.234 4.775 16.125 6.325 17.675 C 7.875 19.225 9.766 20 12 20 Z M 12 12 Z',
  // prettier-ignore
  checkStrikethrough: 'M 20.499 23 L 17.593 20.092 C 16.805 20.617 15.956 21.019 15.045 21.299 C 14.135 21.579 13.17 21.718 12.152 21.718 C 10.79 21.718 9.511 21.459 8.312 20.942 C 7.114 20.425 6.072 19.722 5.186 18.834 C 4.299 17.948 3.597 16.903 3.08 15.704 C 2.564 14.505 2.305 13.224 2.305 11.86 C 2.305 10.84 2.445 9.875 2.723 8.962 C 3.003 8.051 3.405 7.2 3.93 6.412 L 1 3.479 L 2.403 2.074 L 21.902 21.595 L 20.499 23 Z M 12.152 19.746 C 12.892 19.746 13.592 19.649 14.258 19.451 C 14.922 19.253 15.55 18.982 16.141 18.638 L 12.325 14.818 L 10.774 16.395 L 6.588 12.204 L 7.967 10.824 L 10.774 13.634 L 10.945 13.436 L 5.382 7.867 C 5.038 8.458 4.767 9.087 4.57 9.752 C 4.372 10.417 4.274 11.12 4.274 11.86 C 4.274 14.044 5.042 15.905 6.576 17.443 C 8.11 18.979 9.969 19.746 12.152 19.746 Z M 20.4 17.281 L 18.947 15.828 C 19.292 15.252 19.559 14.632 19.748 13.967 C 19.935 13.301 20.03 12.598 20.03 11.86 C 20.03 9.674 19.263 7.813 17.728 6.276 C 16.194 4.741 14.335 3.972 12.152 3.972 C 11.414 3.972 10.713 4.067 10.048 4.256 C 9.383 4.445 8.763 4.711 8.189 5.056 L 6.736 3.603 C 7.523 3.093 8.373 2.699 9.285 2.419 C 10.195 2.139 11.152 2 12.152 2 C 13.515 2 14.795 2.259 15.993 2.776 C 17.191 3.293 18.233 3.996 19.12 4.884 C 20.006 5.771 20.708 6.815 21.224 8.015 C 21.741 9.214 22 10.495 22 11.86 C 22 12.861 21.861 13.819 21.581 14.73 C 21.303 15.642 20.909 16.493 20.4 17.281 Z M 15.156 12.007 L 13.778 10.627 L 16.338 8.064 L 17.716 9.444 L 15.156 12.007 Z M 13.58 10.43 Z M 10.774 13.239 Z',
  // prettier-ignore
  notification: 'M1.5 7.5C1.5 6.25 1.77813 5.10312 2.33438 4.05937C2.89062 3.01562 3.6375 2.15 4.575 1.4625L5.45625 2.6625C4.70625 3.2125 4.10938 3.90625 3.66563 4.74375C3.22188 5.58125 3 6.5 3 7.5H1.5ZM15 7.5C15 6.5 14.7781 5.58125 14.3344 4.74375C13.8906 3.90625 13.2938 3.2125 12.5438 2.6625L13.425 1.4625C14.3625 2.15 15.1094 3.01562 15.6656 4.05937C16.2219 5.10312 16.5 6.25 16.5 7.5H15ZM3 14.25V12.75H4.5V7.5C4.5 6.4625 4.8125 5.54062 5.4375 4.73437C6.0625 3.92812 6.875 3.4 7.875 3.15V2.625C7.875 2.3125 7.98438 2.04687 8.20312 1.82812C8.42188 1.60937 8.6875 1.5 9 1.5C9.3125 1.5 9.57812 1.60937 9.79688 1.82812C10.0156 2.04687 10.125 2.3125 10.125 2.625V3.15C11.125 3.4 11.9375 3.92812 12.5625 4.73437C13.1875 5.54062 13.5 6.4625 13.5 7.5V12.75H15V14.25H3ZM9 16.5C8.5875 16.5 8.23438 16.3531 7.94063 16.0594C7.64688 15.7656 7.5 15.4125 7.5 15H10.5C10.5 15.4125 10.3531 15.7656 10.0594 16.0594C9.76562 16.3531 9.4125 16.5 9 16.5ZM6 12.75H12V7.5C12 6.675 11.7063 5.96875 11.1187 5.38125C10.5312 4.79375 9.825 4.5 9 4.5C8.175 4.5 7.46875 4.79375 6.88125 5.38125C6.29375 5.96875 6 6.675 6 7.5V12.75Z',
  // prettier-ignore
  adUnit: 'M8 9V7H16V9H8ZM7 23C6.45 23 5.97917 22.8042 5.5875 22.4125C5.19583 22.0208 5 21.55 5 21V3C5 2.45 5.19583 1.97917 5.5875 1.5875C5.97917 1.19583 6.45 1 7 1H17C17.55 1 18.0208 1.19583 18.4125 1.5875C18.8042 1.97917 19 2.45 19 3V21C19 21.55 18.8042 22.0208 18.4125 22.4125C18.0208 22.8042 17.55 23 17 23H7ZM7 20V21H17V20H7ZM7 18H17V6H7V18ZM7 4H17V3H7V4Z',
  // prettier-ignore
  randstad: 'M11.4962 15.5261H10.0592V11.5146C10.0592 10.9159 9.58024 10.4369 8.98151 10.4369H5V9H8.62228C8.8019 9 9.01145 9.08981 9.13119 9.20955L11.2866 11.365C11.4063 11.4847 11.4962 11.6643 11.4962 11.8739V15.5261ZM12.2446 15.5261H13.6815V11.5146C13.6815 10.9159 14.1605 10.4369 14.7592 10.4369H18.7707V9H15.1185C14.9389 9 14.7293 9.08981 14.6096 9.20955L12.4542 11.365C12.3344 11.4847 12.2446 11.6643 12.2446 11.8739V15.5261Z'
}
