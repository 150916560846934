export const AccountNotifications = () => import('../../components/app/account/accountNotifications.vue' /* webpackChunkName: "components/account-notifications" */).then(c => wrapFunctional(c.default || c))
export const AccountSecurity = () => import('../../components/app/account/accountSecurity.vue' /* webpackChunkName: "components/account-security" */).then(c => wrapFunctional(c.default || c))
export const AccountSecurityId = () => import('../../components/app/account/accountSecurityId.vue' /* webpackChunkName: "components/account-security-id" */).then(c => wrapFunctional(c.default || c))
export const AccountVerifiedDevices = () => import('../../components/app/account/accountVerifiedDevices.vue' /* webpackChunkName: "components/account-verified-devices" */).then(c => wrapFunctional(c.default || c))
export const ApplicationStatusChip = () => import('../../components/app/application/ApplicationStatusChip.vue' /* webpackChunkName: "components/application-status-chip" */).then(c => wrapFunctional(c.default || c))
export const FlowGridElement = () => import('../../components/app/application/FlowGridElement.vue' /* webpackChunkName: "components/flow-grid-element" */).then(c => wrapFunctional(c.default || c))
export const CalendarComp = () => import('../../components/app/application/calendarComp.vue' /* webpackChunkName: "components/calendar-comp" */).then(c => wrapFunctional(c.default || c))
export const ChildAnswersList = () => import('../../components/app/application/childAnswersList.vue' /* webpackChunkName: "components/child-answers-list" */).then(c => wrapFunctional(c.default || c))
export const ChildQuestions = () => import('../../components/app/application/childQuestions.vue' /* webpackChunkName: "components/child-questions" */).then(c => wrapFunctional(c.default || c))
export const CvBox = () => import('../../components/app/application/cvBox.vue' /* webpackChunkName: "components/cv-box" */).then(c => wrapFunctional(c.default || c))
export const LegacyFlow = () => import('../../components/app/application/legacyFlow.vue' /* webpackChunkName: "components/legacy-flow" */).then(c => wrapFunctional(c.default || c))
export const ModernFlow = () => import('../../components/app/application/modernFlow.vue' /* webpackChunkName: "components/modern-flow" */).then(c => wrapFunctional(c.default || c))
export const UserTerms = () => import('../../components/app/application/userTerms.vue' /* webpackChunkName: "components/user-terms" */).then(c => wrapFunctional(c.default || c))
export const Duration = () => import('../../components/app/filters/duration.vue' /* webpackChunkName: "components/duration" */).then(c => wrapFunctional(c.default || c))
export const Hours = () => import('../../components/app/filters/hours.vue' /* webpackChunkName: "components/hours" */).then(c => wrapFunctional(c.default || c))
export const IncludeClosed = () => import('../../components/app/filters/includeClosed.vue' /* webpackChunkName: "components/include-closed" */).then(c => wrapFunctional(c.default || c))
export const Location = () => import('../../components/app/filters/location.vue' /* webpackChunkName: "components/location" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/app/filters/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PoolCategories = () => import('../../components/app/filters/poolCategories.vue' /* webpackChunkName: "components/pool-categories" */).then(c => wrapFunctional(c.default || c))
export const TextSearch = () => import('../../components/app/filters/textSearch.vue' /* webpackChunkName: "components/text-search" */).then(c => wrapFunctional(c.default || c))
export const WorkPreference = () => import('../../components/app/filters/workPreference.vue' /* webpackChunkName: "components/work-preference" */).then(c => wrapFunctional(c.default || c))
export const PoolCard = () => import('../../components/app/pools/poolCard.vue' /* webpackChunkName: "components/pool-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSummary = () => import('../../components/app/project/ApplicationSummary.vue' /* webpackChunkName: "components/application-summary" */).then(c => wrapFunctional(c.default || c))
export const PreviousApplicationsDrawer = () => import('../../components/app/project/PreviousApplicationsDrawer.vue' /* webpackChunkName: "components/previous-applications-drawer" */).then(c => wrapFunctional(c.default || c))
export const ReferralModal = () => import('../../components/app/project/ReferralModal.vue' /* webpackChunkName: "components/referral-modal" */).then(c => wrapFunctional(c.default || c))
export const PreApplicationQuestions = () => import('../../components/app/project/preApplicationQuestions.vue' /* webpackChunkName: "components/pre-application-questions" */).then(c => wrapFunctional(c.default || c))
export const ShowProject = () => import('../../components/app/project/showProject.vue' /* webpackChunkName: "components/show-project" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCard = () => import('../../components/app/search/applicationCard.vue' /* webpackChunkName: "components/application-card" */).then(c => wrapFunctional(c.default || c))
export const Applications = () => import('../../components/app/search/applications.vue' /* webpackChunkName: "components/applications" */).then(c => wrapFunctional(c.default || c))
export const CardContent = () => import('../../components/app/search/cardContent.vue' /* webpackChunkName: "components/card-content" */).then(c => wrapFunctional(c.default || c))
export const Invitations = () => import('../../components/app/search/invitations.vue' /* webpackChunkName: "components/invitations" */).then(c => wrapFunctional(c.default || c))
export const JobsBlock = () => import('../../components/app/search/jobsBlock.vue' /* webpackChunkName: "components/jobs-block" */).then(c => wrapFunctional(c.default || c))
export const MobileSearchPanel = () => import('../../components/app/search/mobileSearchPanel.vue' /* webpackChunkName: "components/mobile-search-panel" */).then(c => wrapFunctional(c.default || c))
export const NoResultsOffer = () => import('../../components/app/search/noResultsOffer.vue' /* webpackChunkName: "components/no-results-offer" */).then(c => wrapFunctional(c.default || c))
export const Results = () => import('../../components/app/search/results.vue' /* webpackChunkName: "components/results" */).then(c => wrapFunctional(c.default || c))
export const ResultsEmpty = () => import('../../components/app/search/resultsEmpty.vue' /* webpackChunkName: "components/results-empty" */).then(c => wrapFunctional(c.default || c))
export const SearchBanner = () => import('../../components/app/search/searchBanner.vue' /* webpackChunkName: "components/search-banner" */).then(c => wrapFunctional(c.default || c))
export const SearchHeader = () => import('../../components/app/search/searchHeader.vue' /* webpackChunkName: "components/search-header" */).then(c => wrapFunctional(c.default || c))
export const SearchImage = () => import('../../components/app/search/searchImage.vue' /* webpackChunkName: "components/search-image" */).then(c => wrapFunctional(c.default || c))
export const SearchPanel = () => import('../../components/app/search/searchPanel.vue' /* webpackChunkName: "components/search-panel" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCoverLetterBoth = () => import('../../components/app/wizzardApplication/coverLetterBoth.vue' /* webpackChunkName: "components/wizzard-application-cover-letter-both" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCoverLetterInput = () => import('../../components/app/wizzardApplication/coverLetterInput.vue' /* webpackChunkName: "components/wizzard-application-cover-letter-input" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCoverLetterUploader = () => import('../../components/app/wizzardApplication/coverLetterUploader.vue' /* webpackChunkName: "components/wizzard-application-cover-letter-uploader" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCvsBoth = () => import('../../components/app/wizzardApplication/cvsBoth.vue' /* webpackChunkName: "components/wizzard-application-cvs-both" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCvsGenerator = () => import('../../components/app/wizzardApplication/cvsGenerator.vue' /* webpackChunkName: "components/wizzard-application-cvs-generator" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationCvsUploader = () => import('../../components/app/wizzardApplication/cvsUploader.vue' /* webpackChunkName: "components/wizzard-application-cvs-uploader" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationInterview = () => import('../../components/app/wizzardApplication/interview.vue' /* webpackChunkName: "components/wizzard-application-interview" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationQuestions = () => import('../../components/app/wizzardApplication/questions.vue' /* webpackChunkName: "components/wizzard-application-questions" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationRates = () => import('../../components/app/wizzardApplication/rates.vue' /* webpackChunkName: "components/wizzard-application-rates" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationSecId = () => import('../../components/app/wizzardApplication/secId.vue' /* webpackChunkName: "components/wizzard-application-sec-id" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationSummary = () => import('../../components/app/wizzardApplication/summary.vue' /* webpackChunkName: "components/wizzard-application-summary" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsClInput = () => import('../../components/app/wizzardApplication/forms/clInput.vue' /* webpackChunkName: "components/wizzard-application-forms-cl-input" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsClUploader = () => import('../../components/app/wizzardApplication/forms/clUploader.vue' /* webpackChunkName: "components/wizzard-application-forms-cl-uploader" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsCvGenerator = () => import('../../components/app/wizzardApplication/forms/cvGenerator.vue' /* webpackChunkName: "components/wizzard-application-forms-cv-generator" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsCvUploader = () => import('../../components/app/wizzardApplication/forms/cvUploader.vue' /* webpackChunkName: "components/wizzard-application-forms-cv-uploader" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsFilePicker = () => import('../../components/app/wizzardApplication/forms/filePicker.vue' /* webpackChunkName: "components/wizzard-application-forms-file-picker" */).then(c => wrapFunctional(c.default || c))
export const WizzardApplicationFormsProfileModal = () => import('../../components/app/wizzardApplication/forms/profileModal.vue' /* webpackChunkName: "components/wizzard-application-forms-profile-modal" */).then(c => wrapFunctional(c.default || c))
export const I18n = () => import('../../components/app/i18n.vue' /* webpackChunkName: "components/i18n" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/app/login/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const LoginAllMethodsButton = () => import('../../components/app/login/loginAllMethodsButton.vue' /* webpackChunkName: "components/login-all-methods-button" */).then(c => wrapFunctional(c.default || c))
export const LoginButton = () => import('../../components/app/login/loginButton.vue' /* webpackChunkName: "components/login-button" */).then(c => wrapFunctional(c.default || c))
export const LoginCard = () => import('../../components/app/login/loginCard.vue' /* webpackChunkName: "components/login-card" */).then(c => wrapFunctional(c.default || c))
export const LoginOneTimeLinkNotification = () => import('../../components/app/login/loginOneTimeLinkNotification.vue' /* webpackChunkName: "components/login-one-time-link-notification" */).then(c => wrapFunctional(c.default || c))
export const LoginSavedMethod = () => import('../../components/app/login/loginSavedMethod.vue' /* webpackChunkName: "components/login-saved-method" */).then(c => wrapFunctional(c.default || c))
export const AuthButton = () => import('../../components/global/AuthButton.vue' /* webpackChunkName: "components/auth-button" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationCore = () => import('../../components/global/confirmationCore.vue' /* webpackChunkName: "components/confirmation-core" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../components/global/countrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/global/datePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DeleteConfirmation = () => import('../../components/global/deleteConfirmation.vue' /* webpackChunkName: "components/delete-confirmation" */).then(c => wrapFunctional(c.default || c))
export const Expander = () => import('../../components/global/expander.vue' /* webpackChunkName: "components/expander" */).then(c => wrapFunctional(c.default || c))
export const FetchError = () => import('../../components/global/fetchError.vue' /* webpackChunkName: "components/fetch-error" */).then(c => wrapFunctional(c.default || c))
export const FileUploader = () => import('../../components/global/fileUploader.vue' /* webpackChunkName: "components/file-uploader" */).then(c => wrapFunctional(c.default || c))
export const MobilePhoneSelector = () => import('../../components/global/mobilePhoneSelector.vue' /* webpackChunkName: "components/mobile-phone-selector" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/global/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NotificationSettings = () => import('../../components/global/notificationSettings.vue' /* webpackChunkName: "components/notification-settings" */).then(c => wrapFunctional(c.default || c))
export const OfflineBar = () => import('../../components/global/offlineBar.vue' /* webpackChunkName: "components/offline-bar" */).then(c => wrapFunctional(c.default || c))
export const PhoneInput = () => import('../../components/global/phoneInput.vue' /* webpackChunkName: "components/phone-input" */).then(c => wrapFunctional(c.default || c))
export const PublicLinksBlock = () => import('../../components/global/publicLinksBlock.vue' /* webpackChunkName: "components/public-links-block" */).then(c => wrapFunctional(c.default || c))
export const SecurityIds = () => import('../../components/global/securityIds.vue' /* webpackChunkName: "components/security-ids" */).then(c => wrapFunctional(c.default || c))
export const VerificationLabel = () => import('../../components/global/verificationLabel.vue' /* webpackChunkName: "components/verification-label" */).then(c => wrapFunctional(c.default || c))
export const WelcomeModal = () => import('../../components/global/welcomeModal.vue' /* webpackChunkName: "components/welcome-modal" */).then(c => wrapFunctional(c.default || c))
export const Wizzard = () => import('../../components/global/wizzard.vue' /* webpackChunkName: "components/wizzard" */).then(c => wrapFunctional(c.default || c))
export const AppAnnouncement = () => import('../../components/layout/appAnnouncement.vue' /* webpackChunkName: "components/app-announcement" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/layout/appBanner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/layout/appFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/layout/appHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLangChooser = () => import('../../components/layout/appLangChooser.vue' /* webpackChunkName: "components/app-lang-chooser" */).then(c => wrapFunctional(c.default || c))
export const AppTermsExpired = () => import('../../components/layout/appTermsExpired.vue' /* webpackChunkName: "components/app-terms-expired" */).then(c => wrapFunctional(c.default || c))
export const ErrorAlert = () => import('../../components/layout/errorAlert.vue' /* webpackChunkName: "components/error-alert" */).then(c => wrapFunctional(c.default || c))
export const Loaders = () => import('../../components/layout/loaders.vue' /* webpackChunkName: "components/loaders" */).then(c => wrapFunctional(c.default || c))
export const ProfileHolderItem = () => import('../../components/Profile/HolderItem.vue' /* webpackChunkName: "components/profile-holder-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileHolderPage = () => import('../../components/Profile/HolderPage.vue' /* webpackChunkName: "components/profile-holder-page" */).then(c => wrapFunctional(c.default || c))
export const ProfileHolderTitle = () => import('../../components/Profile/HolderTitle.vue' /* webpackChunkName: "components/profile-holder-title" */).then(c => wrapFunctional(c.default || c))
export const ProfileManageAvailability = () => import('../../components/Profile/ManageAvailability.vue' /* webpackChunkName: "components/profile-manage-availability" */).then(c => wrapFunctional(c.default || c))
export const ProfileSecurityIds = () => import('../../components/Profile/SecurityIds.vue' /* webpackChunkName: "components/profile-security-ids" */).then(c => wrapFunctional(c.default || c))
export const ProfileTalentPersonal = () => import('../../components/Profile/TalentPersonal.vue' /* webpackChunkName: "components/profile-talent-personal" */).then(c => wrapFunctional(c.default || c))
export const ProfileTalentRate = () => import('../../components/Profile/TalentRate.vue' /* webpackChunkName: "components/profile-talent-rate" */).then(c => wrapFunctional(c.default || c))
export const FlowAttachmentUploader = () => import('../../components/Flow/AttachmentUploader.vue' /* webpackChunkName: "components/flow-attachment-uploader" */).then(c => wrapFunctional(c.default || c))
export const FlowCheckbox = () => import('../../components/Flow/Checkbox.vue' /* webpackChunkName: "components/flow-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FlowCvGenerator = () => import('../../components/Flow/CvGenerator.vue' /* webpackChunkName: "components/flow-cv-generator" */).then(c => wrapFunctional(c.default || c))
export const FlowCvUploader = () => import('../../components/Flow/CvUploader.vue' /* webpackChunkName: "components/flow-cv-uploader" */).then(c => wrapFunctional(c.default || c))
export const FlowDatePicker = () => import('../../components/Flow/DatePicker.vue' /* webpackChunkName: "components/flow-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FlowHistoryRepeater = () => import('../../components/Flow/HistoryRepeater.vue' /* webpackChunkName: "components/flow-history-repeater" */).then(c => wrapFunctional(c.default || c))
export const FlowMotivationInput = () => import('../../components/Flow/MotivationInput.vue' /* webpackChunkName: "components/flow-motivation-input" */).then(c => wrapFunctional(c.default || c))
export const FlowPhoneNotificationConsent = () => import('../../components/Flow/PhoneNotificationConsent.vue' /* webpackChunkName: "components/flow-phone-notification-consent" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerCategories = () => import('../../components/Flow/PickerCategories.vue' /* webpackChunkName: "components/flow-picker-categories" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerLanguage = () => import('../../components/Flow/PickerLanguage.vue' /* webpackChunkName: "components/flow-picker-language" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerLeCountries = () => import('../../components/Flow/PickerLeCountries.vue' /* webpackChunkName: "components/flow-picker-le-countries" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerLocation = () => import('../../components/Flow/PickerLocation.vue' /* webpackChunkName: "components/flow-picker-location" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerPools = () => import('../../components/Flow/PickerPools.vue' /* webpackChunkName: "components/flow-picker-pools" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerRadius = () => import('../../components/Flow/PickerRadius.vue' /* webpackChunkName: "components/flow-picker-radius" */).then(c => wrapFunctional(c.default || c))
export const FlowPickerTags = () => import('../../components/Flow/PickerTags.vue' /* webpackChunkName: "components/flow-picker-tags" */).then(c => wrapFunctional(c.default || c))
export const FlowProfileAddress = () => import('../../components/Flow/ProfileAddress.vue' /* webpackChunkName: "components/flow-profile-address" */).then(c => wrapFunctional(c.default || c))
export const FlowRateField = () => import('../../components/Flow/RateField.vue' /* webpackChunkName: "components/flow-rate-field" */).then(c => wrapFunctional(c.default || c))
export const FlowReadonly = () => import('../../components/Flow/Readonly.vue' /* webpackChunkName: "components/flow-readonly" */).then(c => wrapFunctional(c.default || c))
export const FlowSecurityId = () => import('../../components/Flow/SecurityId.vue' /* webpackChunkName: "components/flow-security-id" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorButton = () => import('../../components/Flow/SelectorButton.vue' /* webpackChunkName: "components/flow-selector-button" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorCalendar = () => import('../../components/Flow/SelectorCalendar.vue' /* webpackChunkName: "components/flow-selector-calendar" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorCheck = () => import('../../components/Flow/SelectorCheck.vue' /* webpackChunkName: "components/flow-selector-check" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorCountry = () => import('../../components/Flow/SelectorCountry.vue' /* webpackChunkName: "components/flow-selector-country" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorPhone = () => import('../../components/Flow/SelectorPhone.vue' /* webpackChunkName: "components/flow-selector-phone" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorPlain = () => import('../../components/Flow/SelectorPlain.vue' /* webpackChunkName: "components/flow-selector-plain" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorRadio = () => import('../../components/Flow/SelectorRadio.vue' /* webpackChunkName: "components/flow-selector-radio" */).then(c => wrapFunctional(c.default || c))
export const FlowSelectorReferral = () => import('../../components/Flow/SelectorReferral.vue' /* webpackChunkName: "components/flow-selector-referral" */).then(c => wrapFunctional(c.default || c))
export const FlowSwitchDisabler = () => import('../../components/Flow/SwitchDisabler.vue' /* webpackChunkName: "components/flow-switch-disabler" */).then(c => wrapFunctional(c.default || c))
export const FlowTextArea = () => import('../../components/Flow/TextArea.vue' /* webpackChunkName: "components/flow-text-area" */).then(c => wrapFunctional(c.default || c))
export const FlowTextEditor = () => import('../../components/Flow/TextEditor.vue' /* webpackChunkName: "components/flow-text-editor" */).then(c => wrapFunctional(c.default || c))
export const FlowTextField = () => import('../../components/Flow/TextField.vue' /* webpackChunkName: "components/flow-text-field" */).then(c => wrapFunctional(c.default || c))
export const FlowTextSocial = () => import('../../components/Flow/TextSocial.vue' /* webpackChunkName: "components/flow-text-social" */).then(c => wrapFunctional(c.default || c))
export const FlowTimePicker = () => import('../../components/Flow/TimePicker.vue' /* webpackChunkName: "components/flow-time-picker" */).then(c => wrapFunctional(c.default || c))
export const FlowUiPlainText = () => import('../../components/Flow/UiPlainText.vue' /* webpackChunkName: "components/flow-ui-plain-text" */).then(c => wrapFunctional(c.default || c))
export const FlowUploadAttachments = () => import('../../components/Flow/UploadAttachments.vue' /* webpackChunkName: "components/flow-upload-attachments" */).then(c => wrapFunctional(c.default || c))
export const FlowUploadDocument = () => import('../../components/Flow/UploadDocument.vue' /* webpackChunkName: "components/flow-upload-document" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutAppRootPage = () => import('../../components/FlowLayout/AppRootPage.vue' /* webpackChunkName: "components/flow-layout-app-root-page" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutDisplayAttachment = () => import('../../components/FlowLayout/DisplayAttachment.vue' /* webpackChunkName: "components/flow-layout-display-attachment" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutDisplayToggler = () => import('../../components/FlowLayout/DisplayToggler.vue' /* webpackChunkName: "components/flow-layout-display-toggler" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutDrawerDetails = () => import('../../components/FlowLayout/DrawerDetails.vue' /* webpackChunkName: "components/flow-layout-drawer-details" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutDrawerTimeline = () => import('../../components/FlowLayout/DrawerTimeline.vue' /* webpackChunkName: "components/flow-layout-drawer-timeline" */).then(c => wrapFunctional(c.default || c))
export const FlowLayoutRootPage = () => import('../../components/FlowLayout/RootPage.vue' /* webpackChunkName: "components/flow-layout-root-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
