export default (axios) => ({
  getBreadcrumbs() {
    return axios.get(`/v1/api/breadcrumbs`)
  },

  createAffiliateCookies(query) {
    return axios.get('/v1/api/affiliate', {
      params: {
        ...query
      }
    })
  },

  getLanguages() {
    return axios.get('/v1/api/languages')
  },
  getCountry() {
    return axios.get('/v1/api/country')
  }
})
