import { v4 } from 'uuid'

export default async function ({ app, store, $auth }) {
  const cookieId = '_brws_id'
  const uuid = $auth.$storage.getCookie(cookieId)

  if (!uuid) {
    const expires = store.state.clientConfig.userDeviceValidity
    await $auth.$storage.setCookie(cookieId, v4(), { expires, httpOnly: false })
  }
}
