export default async function ({ app, $auth }) {
  if ($auth.loggedIn) {
    await $auth.fetchUser()
  }

  if ($auth.loggedIn && app.$verificationStatus('DE')) {
    console.log('[<--HOLD ON TIGER! YOU ARE DEACTIVATED--> ]')
    await $auth.logout('local')
  }
}
