
export default {
  name: 'AppAnnouncement',

  data() {
    return {
      announce: false,
      deadline: '',
      message: '',
      actionText: ''
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        try {
          const { data } = await this.$axios.get('/napi/announcement')

          if (data) {
            this.deadline = this.$moment(data.deadline)
            this.triggerAnnouncement(data.deadline_with_leadtime)
          } else {
            this.announce = false
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  },

  methods: {
    snooze() {
      const now = this.$moment(new Date())
      const diff = this.deadline.diff(now, 'seconds')

      if (diff < 600) {
        if (!this.$auth.$storage.getState('tenSnooze')) {
          this.$auth.$storage.setState('tenSnooze', true)
        }
      } else if (diff < 1800) {
        if (!this.$auth.$storage.getState('thirtySnooze')) {
          this.$auth.$storage.setState('thirtySnooze', true)
        }
      } else if (diff >= 1800) {
        if (!this.$auth.$storage.getState('longSnooze')) {
          this.$auth.$storage.setState('longSnooze', true)
        }
      }

      this.announce = false
    },

    triggerAnnouncement(deadlineMinusLeadtime) {
      const now = this.$moment(new Date())
      const deadlineWithLeadtime = this.$moment(deadlineMinusLeadtime)
      const diff = deadlineWithLeadtime.diff(now, 'seconds')

      if (diff < 90) {
        this.announce = true
        this.actionText = ''
        this.message = this.$t('talent.announcment.banner.one', {
          time: this.deadline.format('HH:mm')
        })
      } else if (diff < 600) {
        if (!this.$auth.$storage.getState('tenSnooze')) {
          this.announce = true
          this.actionText = this.$t('talent.announcment.banner.snooze')
          this.message = this.$t('talent.announcment.banner.ten', {
            time: this.deadline.format('HH:mm')
          })
        }
      } else if (diff < 1800) {
        if (!this.$auth.$storage.getState('thirtySnooze')) {
          this.announce = true
          this.actionText = this.$t('talent.announcment.banner.snooze')
          this.message = this.$t('talent.announcment.banner.thirty', {
            time: this.deadline.format('HH:mm')
          })
        }
      } else if (diff >= 1800) {
        if (!this.$auth.$storage.getState('longSnooze')) {
          this.announce = true
          this.actionText = this.$t('talent.announcment.banner.snooze')
          this.message = this.$t('talent.announcment.banner.long', {
            time: this.deadline.format('HH:mm')
          })
        }
      }
    }
  }
}
