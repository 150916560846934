import { DEFAULT_COUNTRY } from '@/helpers/consts'
import { DEFAULT_CONTEXT } from '@/config/config'
import { patchBrwsId } from '@/helpers/patch'

export const state = () => ({
  clientContext: { ...DEFAULT_CONTEXT },
  clientConfig: {},
  leConfigs: new Map(),
  sessionCountry: '',
  reqCookies: '',
  actionLoader: false,
  actionLoaderCaption: undefined,
  pageLoader: false,
  countriesList: [],
  hiddenFields: [],
  seoIndex: true,
  emailRegisterToLogin: '',
  locale: '',
  messages: new Map()
})

export const actions = {
  async nuxtServerInit({ commit, dispatch }, context) {
    const { app, req, res, route } = context

    try {
      if (
        route.query.r ||
        route.query.utm_source ||
        route.query.utm_medium ||
        route.query.utm_campaign ||
        route.query.utm_content
      ) {
        const queryObj = { page: route.path }

        const assignParamFromRoute = (to, paramName) => {
          if (route.query[paramName]) {
            to[paramName] = Array.isArray(route.query[paramName])
              ? encodeURIComponent(route.query[paramName][0])
              : encodeURIComponent(route.query[paramName])
          }
        }

        assignParamFromRoute(queryObj, 'r')
        assignParamFromRoute(queryObj, 'utm_source')
        assignParamFromRoute(queryObj, 'utm_medium')
        assignParamFromRoute(queryObj, 'utm_campaign')
        assignParamFromRoute(queryObj, 'utm_content')

        const headers = res.getHeader('Set-Cookie') || []
        const getAffiliate = await app.$api.utils.createAffiliateCookies(queryObj)

        if (getAffiliate.headers['set-cookie']) {
          headers.push(...getAffiliate.headers['set-cookie'])
          res.set('Set-Cookie', headers)
        }

        await commit('SET_REQ_COOKIES', req.headers.cookie || '')
      }
    } catch (error) {}

    const pullCountries = dispatch('pullCountries')
    const getPools = this.dispatch('filters/getPools')
    const getBreadcrumbs = this.dispatch('publicSearch/getBreadcrumbs')

    await Promise.all([pullCountries, getPools, getBreadcrumbs])
    patchBrwsId(req, res)
  },

  async pullCountries({ commit }) {
    try {
      const { data } = await this.$api.utils.getCountry()
      const countries = []

      const currentLocale = this.$defineLocale(this.app.$i18n.locale)
      const translate = (item) => {
        const translations = Object.entries(item.translations)
        const lang = translations.find(([key]) => key === currentLocale)

        return lang?.[1] ?? item.name
      }

      for (const country of data) {
        const callingCode = country.phoneCode.split('and')[0]
        if (callingCode === '') continue

        countries.push({
          text: translate(country),
          value: country.iso2,
          callingCode: `+${[...callingCode.matchAll(/\d/g)].join('')}`
        })
      }

      const clientPref = this.$useConfig(this.$EClientConfig.PREFERRED_SORTED_COUNTRIES)
      const sortFn = clientPref?.length
        ? (a, b) => {
            const aIdx = clientPref.indexOf(a.value)
            const bIdx = clientPref.indexOf(b.value)

            if (~aIdx && ~bIdx) return aIdx - bIdx
            if (~aIdx) return -1
            if (~bIdx) return 1
            return a.text.localeCompare(b.text)
          }
        : (a, b) => a.text.localeCompare(b.text)

      countries.sort(sortFn)

      commit('COUNTRIES_LIST', countries)
    } catch (err) {
      console.error(err)
    }
  },

  async getHiddenFields({ commit }) {
    try {
      const { data } = await this.$api.freelancer.getHiddenProfileFields()
      commit('HIDDEN_FIELDS', data.hiddenFieldNames)
    } catch (error) {}
  }
}

export const mutations = {
  SET_CLIENT_CONTEXT: (state, payload) => {
    state.clientContext = payload
  },

  SET_CLIENT_CONFIG: (state, payload) => {
    state.clientConfig = payload
  },

  SET_LE_CONFIGS: (state, payload) => {
    state.leConfigs = payload
  },

  SESSION_COUNTRY: (state, payload) => {
    state.sessionCountry = payload
  },

  SET_REQ_COOKIES: (state, payload) => {
    state.reqCookies = payload
  },

  COUNTRIES_LIST: (state, payload) => {
    state.countriesList = payload
  },

  HIDDEN_FIELDS: (state, payload) => {
    state.hiddenFields = payload
  },

  ACTION_LOADER: (state, payload) => {
    state.actionLoader = payload
  },

  ACTION_LOADER_CAPTION: (state, payload) => {
    state.actionLoaderCaption = payload
  },

  PAGE_LOADER: (state, payload) => {
    state.pageLoader = payload
  },

  SEO_INDEX: (state, payload) => {
    state.seoIndex = payload
  },
  EMAIL_REGISTER_TO_LOGIN: (state, payload) => {
    state.emailRegisterToLogin = payload
  },

  SET_LOCALE: (state, locale) => {
    state.locale = locale
  },

  SET_MESSAGES: (state, messages) => {
    state.messages = new Map(Object.entries(messages))
  }
}

export const getters = {
  countriesList: (state) => {
    return state.countriesList
  },

  hiddenFields: (state) => {
    return state.hiddenFields
  },

  actionLoader: (state) => {
    return state.actionLoader
  },

  actionLoaderCaption: (state) => {
    return state.actionLoaderCaption
  },

  pageLoader: (state) => {
    return state.pageLoader
  },
  emailRegisterToLogin: (state) => {
    return state.emailRegisterToLogin
  },

  defaultCountry: ({ sessionCountry }) => sessionCountry || DEFAULT_COUNTRY,

  locales: ({ clientContext }) => clientContext.languages?.map((e) => e.iso) || []
}
