
export default {
  name: 'AppFooter',

  data() {
    return {
      clientName: this.$store.state.clientContext.name,
      footerLinks: []
    }
  },
  async fetch() {
    const { data } = await this.$api.cms.getFooterLinks()
    this.footerLinks = data
  },

  computed: {
    copyright() {
      return this.$t('talent.global.footer.copyright', {
        clientName: this.$store.state.clientContext.name,
        currentYear: this.$displayTime(new Date(), 'YYYY')
      })
    },

    makeEntityBool() {
      const exists = this.$te(`talent.global.${this.clientName}.disclaimer.footer`)
      const verb = this.$t(`talent.global.${this.clientName}.disclaimer.footer`)

      return (exists && verb !== '') || false
    }
  }
}
