
export default {
  name: 'AppLangChooser',

  props: {
    footer: Boolean,

    labelClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    langSelected: {
      get() {
        return this.$i18n.locale
      },
      set(value) {
        this.$triggerLoader('PAGE', true)
        this.$i18n.locale = value
      }
    }
  }
}
