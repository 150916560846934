export default async function ({ app, redirect, route, query, $auth }) {
  if (
    (route.name === 'app-account-activate' && !query?.token) ||
    (route.name === 'app-account-forgotPassword' && !query?.token)
  ) {
    redirect(app.$link.login())
  }

  const isProjectPage = route?.name === 'app-project-id'

  if (isProjectPage && route?.params?.id && query?.token) {
    await $auth.$storage.setUniversal('projectId', route.params.id, true)
  }

  if (route.name !== 'app-account-activate' && !$auth.loggedIn && query?.token) {
    try {
      const { data: getToken } = await app.$api.login.authorizeWithUrlToken({
        urlToken: query.token,
        actorDeviceUUID: app.$trustedToken()
      })

      await $auth.setUserToken(getToken.access_token, getToken.refresh_token)
    } catch (err) {
      if (isProjectPage) {
        const { data: projectData } = await app.$api.project.getProject(route.params.id)
        await $auth.$storage.setUniversal('projectData', projectData, true)
        await app.$auth.$storage.removeUniversal('projectId')
        redirect(app.$link.project(projectData))
      } else {
        redirect(app.$link.login())
      }

      console.error('An unexpected error occured while trying to authorize with URL token')
      console.error(err)
    }
  }
}
