
import { ToastPanel } from '@twago/toast'

export default {
  name: 'Portal',

  components: { ToastPanel },
  middleware: 'profile-fullfilled',

  head() {
    return this.$clientI18nSeo()
  },

  watch: {
    '$nuxt.isFetching': {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$triggerLoader('PAGE', false)
        }
      }
    }
  },

  beforeDestroy() {
    this.$toast.show = false
  }
}
