
import { ToastPanel } from '@twago/toast'

export default {
  name: 'Frameless',

  components: { ToastPanel },

  head() {
    return this.$clientI18nSeo()
  },

  computed: {
    collectedProjectData() {
      const projectData = this.$auth.$storage.getUniversal('projectData', true)

      if (projectData) {
        return projectData
      } else {
        return { id: '' }
      }
    },

    projectInformation() {
      return this.$t('talent.application.apply.header', {
        jobTitle: this.collectedProjectData.title,
        city: this.collectedProjectData.siteCity,
        hours: this.collectedProjectData.hoursPerWeek
      })
    }
  },

  watch: {
    '$nuxt.isFetching': {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$triggerLoader('PAGE', false)
        }
      }
    },

    '$route.path': {
      immediate: true,
      handler(val) {
        this.$triggerLoader('PAGE', true)
      }
    }
  },

  methods: {
    cancelApplication() {
      // prettier-ignore
      this.$router.push(this.$link.project(this.collectedProjectData))
      this.$auth.$storage.removeUniversal('projectData')
    }
  }
}
