import { EFreelancerRequiredVerfications, EClientConfig } from '~/helpers/enums'

export const DEFAULT_CONTEXT = {
  id: 0,
  name: '',
  talentDomainName: '',
  languages: [],
  primaryColor: '',
  talentLogo: '',
  projectLogo: '',
  ogImage: '',
  // below come from BE
  reCaptchaSiteKey: '',
  reCaptchaIsEnabled: false
}

export const FETCH_CONTEXT_FIELDS = [
  'id',
  'name',
  'talent_domain_name',
  'languages',
  'primary_color',
  'talent_logo',
  'project_logo',
  'og_image',
  'legal_entity.name',
  'legal_entity.id',
  'legal_entity.show_logo',
  'legal_entity.logo'
]

export const FETCH_CLIENT_CONFIGS = Object.values(EClientConfig)

export function mapClientConfig(clientConfigs, key) {
  try {
    const rawConfig = clientConfigs.find(({ configKey }) => configKey === key)
    const rawValue = rawConfig?.configValue

    switch (key) {
      case EClientConfig.ADDRESS_VALIDATION:
        return rawValue || {}
      case EClientConfig.CURRENCIES:
        return rawValue ? Object.values(rawValue) : []
      case EClientConfig.ENABLE_COUNTRIES_OF_INTEREST:
        return rawValue?.value || false
      case EClientConfig.ENABLE_EXTERNAL_APP_LINK:
        return rawValue?.value || false
      case EClientConfig.ENABLE_IP_DETECTION:
        return rawValue?.value || false
      case EClientConfig.ENABLE_JOB_NOTIFICATION_DIGEST:
        return rawValue?.value || false
      case EClientConfig.ENABLE_MODERN_APP_FLOW:
        return rawValue?.value || false
      case EClientConfig.ENABLE_PAQ:
        return rawValue?.value || false
      case EClientConfig.ENABLE_PUBLIC_SEARCH:
        return !rawValue?.value
      case EClientConfig.FREELANCER_LOGIN:
        return rawValue || {}
      case EClientConfig.FREELANCER_REQUIRED_VERIFICATIONS:
        return (
          rawValue || {
            email: EFreelancerRequiredVerfications.PRINCIPAL,
            mobileNumber: EFreelancerRequiredVerfications.DISABLED
          }
        )
      case EClientConfig.PREFERRED_SORTED_COUNTRIES:
        return rawValue ? Object.values(rawValue) : []
      case EClientConfig.REGISTER_NOTIFICATION_COUNTRY:
        return rawValue ? Object.values(rawValue) : []
      case EClientConfig.USER_DEVICE_VALIDITY:
        return rawValue?.value || 365
      case EClientConfig.EMAIL_SPECIAL_CHARACTERS:
        return rawValue?.value ?? true
      case EClientConfig.MOBILE_COUNTRIES_SETTINGS:
        return rawValue?.value || {}
      case EClientConfig.FEED_JOVEO_JOBTYPE:
        return rawValue?.value || {}
      default:
        console.error(`Uknown client config: ${key}`)
    }
  } catch (err) {
    console.error(`An error has been caught when parsing ${key} configuration`, err)
  }
}

export const ELeConfig = Object.freeze({
  JOB_FIELD_SETTINGS: 'le__job_field_settings'
})

export const FETCH_LE_CONFIGS = Object.values(ELeConfig)

export function mapLeConfig(configs, key) {
  const rawValue = configs?.get(key)

  try {
    switch (key) {
      case ELeConfig.JOB_FIELD_SETTINGS:
        return rawValue || {}
      default:
        console.error(`Uknown legal entity config: ${key}`)
    }
  } catch (err) {
    console.error(`An error has been caught when parsing ${key} legal entity configuration`, err)
  }
}

export function parseRawLeConfigs(leConfigs) {
  const entityConfigMap = new Map()

  for (const { leName, configKey, configValue } of leConfigs) {
    if (!entityConfigMap.has(leName)) {
      entityConfigMap.set(leName, new Map())
    }

    entityConfigMap.get(leName).set(configKey, configValue)
  }

  return entityConfigMap
}
