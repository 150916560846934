
export default {
  name: 'OfflineBar',

  props: {
    app: Boolean
  },

  data() {
    return {
      clock: '',
      timeInterval: ''
    }
  },

  watch: {
    '$nuxt.isOffline': {
      immediate: true,
      deep: true,
      handler() {
        if (this.$nuxt.isOffline) {
          this.timeInterval = setInterval(this.startTime, 500)
        } else {
          clearInterval(this.timeInterval)
        }
      }
    }
  },

  methods: {
    startTime() {
      const today = new Date()
      const h = today.getHours()
      let m = today.getMinutes()
      let s = today.getSeconds()
      m = this.checkTime(m)
      s = this.checkTime(s)
      this.clock = `${h}:${m}:${s}`
    },

    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    }
  }
}
