import { groupBy, sortBy, pullAt, get } from 'lodash-es'
import { EJSONProps, EFlowElement, EStepNav, EFlowShowOn } from '@twago/flows'
import { EDataId } from '~/helpers/enums'

export default ({ app, store, redirect, route }, inject) => {
  inject('defineFlowValidation', (flowArr, slug) => {
    return flowArr
      .filter((el) => el[EJSONProps.STEP_NAME])
      .map((el) => el[EJSONProps.STEP_NAME])
      .includes(slug)
  })

  inject('defineHiddenFields', (structure) => {
    const filteredList = []

    structure.forEach((e) => {
      const useVisibility = get(e, `optional.${[EJSONProps.OPT_HIDE_BY_COUNTRY]}`)

      if (!useVisibility?.includes(app.$auth.user.residenceCountry)) {
        filteredList.push(e)
      }
    })

    return filteredList
  })

  inject('defineFlowList', ({ flowArr, showOn }) => {
    const useFilteredFields = app.$defineHiddenFields(flowArr)
    const filterShowOn = useFilteredFields.filter((e) => e.showOn.includes(showOn))
    const sortSequence = sortBy(filterShowOn, EJSONProps.STEP_SEQUENCE)
    const getSteps = sortSequence.map((el) => el[EJSONProps.STEP_NAME])
    const uniqSteps = new Set(getSteps)

    return [...uniqSteps]
  })

  inject('defineFlowStructure', (flowArr, showOn) => {
    const useFilteredFields = app.$defineHiddenFields(flowArr)
    const filterShowOn = useFilteredFields.filter((e) => e.showOn.includes(showOn))
    const sortSequence = sortBy(filterShowOn, EJSONProps.STEP_SEQUENCE)
    const groupedSteps = groupBy(sortSequence, EJSONProps.STEP_NAME)

    return groupedSteps
  })

  inject('defineRowStructure', (flowArr) => {
    const useFilteredFields = app.$defineHiddenFields(flowArr)
    const sortSequence = sortBy(useFilteredFields, EJSONProps.ROW_NUMBER)
    const groupRows = groupBy(sortSequence, EJSONProps.ROW_NUMBER)

    if (Object.hasOwnProperty.call(groupRows, 'undefined')) {
      const getUndefined = Object.getOwnPropertyDescriptor(groupRows, 'undefined')
      Object.defineProperty(groupRows, EStepNav.DEFINE, getUndefined)

      delete groupRows.undefined
    }

    return groupRows
  })

  inject('defineStepBuilder', ({ segment, slug, direction }) => {
    const useFlowArray = app.$defineFlowList({
      flowArr: [...store.state.onboarding.structure],
      showOn: segment
    })

    const defineIdx = useFlowArray.findIndex((el) => el === slug)
    let defineStep

    switch (direction) {
      case EStepNav.PREV: {
        defineStep = pullAt([...useFlowArray], defineIdx - 1)
        break
      }
      case EStepNav.NEXT: {
        defineStep = pullAt([...useFlowArray], defineIdx + 1)
        break
      }
    }

    // prettier-ignore
    return defineStep.join('')
      ? app.$link.onboarding(defineStep.join(''))
      : undefined
  })

  inject('defineStepsProgress', ({ array, slug }) => {
    const defineIdx = array.findIndex((el) => el === slug)

    return ((defineIdx + 1) / array.length) * 100
  })

  inject('defineOnboardingType', async ({ $api, $auth }) => {
    const defineProject = $auth.$storage.getUniversal('projectData')

    if (defineProject?.id) {
      await $api.project.getProjectDetailsById(defineProject.id)
      const { data: defineProjectData } = await $api.project.getProject(defineProject.id)

      store.commit('onboarding/USE_PROJECT_DATA', { ...defineProjectData })
      store.commit('onboarding/SET_ONBOARDING_TYPE', EFlowShowOn.SHORT_ONBOARDING)
    }
  })

  inject('defineOnboarding', ({ params }) => {
    let makeSlug = []
    const useType = store.state.onboarding.onboardingType
    const useStepData = app.$defineFlowStructure([...store.state.onboarding.structure], useType)

    if (useStepData[params.slug]) {
      makeSlug = params.slug
    } else {
      makeSlug = Object.keys(useStepData)[0]

      redirect(app.$link.onboarding(makeSlug))
    }

    const useCurrentStep = [...useStepData[makeSlug]]
    const useFlowArray = app.$defineFlowList({
      flowArr: [...store.state.onboarding.structure],
      showOn: useType
    })

    const useProgress = app.$defineStepsProgress({ array: useFlowArray, slug: params.slug })
    const filterShowOn = useCurrentStep.filter((e) => e.showOn.includes(useType))
    const useRows = app.$defineRowStructure(filterShowOn)

    return {
      useCurrentStep,
      useFlowArray,
      useProgress,
      useRows
    }
  })

  inject('asyncStepDataReceiver', async ({ $api, useCurrentStep }) => {
    const defineStepType = useCurrentStep.map((e) => e.type)
    const isCategories = defineStepType.includes(EFlowElement.PICKER_CATEGORIES)
    const isPools = defineStepType.includes(EFlowElement.PICKER_POOLS)

    if (isCategories || isPools) {
      const { data: categories } = await app.$api.cms.getCategories()
      store.commit('onboarding/CATEGORIES_LIST', categories)
    }

    if (isPools) {
      const getSelected = store.state.onboarding.structure.find(
        (e) => e.type === EFlowElement.PICKER_CATEGORIES
      )
      const selectedCategories = [...get(getSelected, 'value', [])]

      const { data: items } = await $api.flow.getPoolsByCategories({
        params: {
          onBoarding: true,
          categoryIds: selectedCategories.join(',') || undefined
        }
      })

      const listPools = items.categoryResults.results.filter((e) => e.pools.length)

      store.commit('onboarding/CATEGORIZED_POOLS_LIST', listPools)
      store.commit('onboarding/ORPHAN_POOLS_LIST', items.orphanPools.results)
    }
  })

  inject('getElementId', (route, type, attrs) => {
    const getSectionFromRoute = () => {
      if (route.includes('profile')) {
        return EDataId.PROFILE
      } else if (route.includes('onboarding')) {
        return EDataId.ONBOARDING
      } else if (route.includes('signup')) {
        return EDataId.SIGNUP
      } else if (route.includes('login')) {
        return EDataId.LOGIN
      }

      return ''
    }

    const prefix = getSectionFromRoute()

    const stepName = attrs?.stepName
    const name = attrs?.name

    if (name && stepName) {
      return `${type}-${prefix}${stepName}-${name}`
    } else if (stepName && !name) {
      return `${type}-${prefix}${stepName}`
    } else if (!stepName && name) {
      return `${type}-${prefix}${name}`
    }
  })
}
